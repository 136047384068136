import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import MaterialTable from "material-table";
import {PROFESSORS_SIMULATION_COLUMN_DEFINITION} from "../../Material-TableCustomization/ColumnsDefinition/ProfessorsSimulationColumnDefinition";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";

export default function ProfessorSubjectsTable(props) {
    const [topics, setTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadTopicsApi = (professorId) => {
        return API.get('misServiciosEscolares', `/topics?professorId=${props.professorId}`);
    };

    const loadTopics = async () => {
        setIsLoading(true);
        try {
            const response = await loadTopicsApi(props.professorId);
            setTopics(response);
        } catch (e) {
            alert('No se pudo encontrar la información deseada. Por favor intente de nuevo.');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadTopics();
    }, [props.professorId, props.history]);

    return (
        <div className="Professors">
            <MaterialTable
                columns={PROFESSORS_SIMULATION_COLUMN_DEFINITION(props.history)}
                data={topics}
                isLoading={isLoading}
                title="Materias Asignadas"
                components={REMOVE_DROP_SHADOW}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                localization={LOCALIZATION('materia', 'materias')}
                options={{
                    search: true,
                    pageSize: 20,
                    filtering: true,
                }}
            />
        </div>
    );
};