import React from "react";
import {getCycles} from "../../CommonUtilities/UtilityFunctions";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

export function ENFERMERIA_STUDENTS_GRADES_TABLE_COLUMN_DEFINITION(props) {
    return [
        {
            title: 'Clave',
            field: 'topicId',
            editable: 'never',
        },
        {
            title: 'Nombre',
            field: 'name',
            editable: 'never',
        },
        {
            title: 'Periodo',
            field: 'topicPeriod',
            lookup: getCycles()
        },
        {
            title: 'Cuatri',
            field: 'cuatrimestre',
            editable: 'never',
        },
        {
            title: '1P',
            field: 'ORDINARIO.FIRST.grade',
            render: rowData => {
                return <>{rowData?.ORDINARIO?.FIRST?.grade}</>;
            }
        },
        {
            title: '2P',
            field: 'ORDINARIO.SECOND.grade',
            render: rowData => {
                return <>{rowData?.ORDINARIO?.SECOND?.grade}</>;
            }
        },
        {
            title: '3P',
            field: 'ORDINARIO.THIRD.grade',
            render: rowData => {
                return <>{rowData?.ORDINARIO?.THIRD?.grade}</>;
            }
        },
        {
            title: 'Cal',
            field: 'grade',
            editable: 'never',
            render: rowData => {
                return <>{rowData.grade
                    ? parseFloat(rowData.grade)
                        ? parseFloat(rowData.grade).toFixed(1)
                        : rowData.grade
                    : ""
                }</>;
            }
        },
        {
            title: 'Extra',
            field: 'extraordinario',
            render: rowData => {
                return <>{rowData.extraordinario
                    ? parseFloat(rowData.extraordinario)
                        ? parseFloat(rowData.extraordinario).toFixed(1)
                        : rowData.extraordinario
                    : ""
                }</>;
            }
        },
        {
            title: 'Equiv',
            field: 'equivalencia',
            render: rowData => {
                return <>{rowData.equivalencia
                    ? parseFloat(rowData.equivalencia)
                        ? parseFloat(rowData.equivalencia).toFixed(1)
                        : rowData.equivalencia
                    : ""
                }</>;
            }
        },
        {
            title: 'EqFolio',
            field: 'equivalenciaFolio',
        },
        {
            title: 'Grupo',
            field: 'externalGroupId',
            render: rowData =>
                rowData.externalGroupId
                    ? <Button
                        color='secondary'
                        onClick={() => props.history.push(`/groups/${rowData?.externalGroupId}`)}
                        // onClick={() => console.log(props, 'props')}
                        component={Link}>
                        Ir al Grupo
                    </Button>
                    : <></>

        },

    ];
}