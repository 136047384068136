import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StudentGradesTable from "./StudentGradesTable";
import StudentDocumentsTable from "./StudentDocumentsTable";
import Registration from "../../../containers/Containers/Registration";
import {REGISTRATION_COMPONENT_TYPE} from "../../../libs/Constants";
import StudentPayments from "./StudentPayments";
import StudentDetailsNotes from "./StudentDetailsNotes";
import StudentPortalDetails from "./StudentPortalDetails";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const K_TABS_TITLES = {
    details: "Detalles",
    grades: "Calificaciones",
    documents: "Documentos",
    payments: "Tesoreria",
    notes: "Notas",
    printing: "Impresión",
    portal: "Portal"
};

const StudentDetailsTabsContainer = ({student, grades, history}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label={K_TABS_TITLES.details} {...a11yProps(0)} />
                    <Tab label={K_TABS_TITLES.grades} {...a11yProps(1)}/>
                    <Tab label={K_TABS_TITLES.documents} {...a11yProps(2)} />
                    <Tab label={K_TABS_TITLES.payments} {...a11yProps(3)} />
                    <Tab label={K_TABS_TITLES.notes} {...a11yProps(4)} />
                    <Tab label={K_TABS_TITLES.portal} {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Registration student={student} type={REGISTRATION_COMPONENT_TYPE.UPDATE_USER}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StudentGradesTable grades={grades} student={student} history={history}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <StudentDocumentsTable studentUuid={student?.studentUuid}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <StudentPayments studentUuid={student?.studentUuid}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <StudentDetailsNotes studentUuid={student?.studentUuid}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <StudentPortalDetails studentUuid={student?.studentUuid}/>
            </TabPanel>

        </div>
    );
};

export default StudentDetailsTabsContainer;