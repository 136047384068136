import React from "react";
import {majors} from "../../CommonUtilities/UtilityFunctions";

export const TOPICS_COLUMN_DEFINITION = [
    {
        title: 'Materias',
        field: 'topicName',
        render: rowData => <a className="studentsName" href={`/topics/${rowData.topicId.split('#')[0]}?groupId=${rowData.topicId.split('#')[1]}`}>
            {rowData.topicName}</a>,
    },
    {
        title: 'Grupo',
        field: 'groupMajor',
        render: rowData => <span>{rowData.groupMajor}-{rowData.groupCurrentCuatrimestre}{rowData.groupGroup}</span>
    },
    {
        title: 'Ciclo',
        field: 'startingCycle',
    },
];