import React, {useState} from "react";
import {Auth} from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Copyright} from "./Copyright";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    input: {
        "&::placeholder": {
            color: "red",
            textAlign: "center"
        },
        textAlign: "center"
    }
}));

export default function ResetPassword() {
    const classes = useStyles();
    const [fields, setFields] = useState({
        code: "",
        email: "",
        password: "",
        confirmPassword: "",
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    function validateCodeForm() {
        return fields.email.length > 0;
    }

    function validateResetForm() {
        return (
            fields.code.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }

    async function handleSendCodeClick(event) {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        } catch (error) {
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(event) {
        event.preventDefault();
        setIsConfirming(true);
        try {
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            setConfirmed(true);
        } catch (error) {
            setIsConfirming(false);
        }
    }

    function renderRequestCodeForm() {
        return (
            <>
                <form className={classes.form} noValidate>
                    <TextField
                        autofocus={true}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Ingresa tu email"
                        value={fields.email}
                        onChange={e => setFields({...fields, email: e.target.value})}
                        // inputProps={{min: 0, style: { textAlign: 'center' }}}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleSendCodeClick}
                        disabled={!validateCodeForm()}
                    >
                        Mandar Código de Confirmación al Email
                    </Button>
                </form>
            </>
        );
    }

    function renderConfirmationForm() {
        return (<>
            <form className={classes.form} noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    autoFocus={true}
                    fullWidth
                    id="code"
                    label="Ingresa tu Código de Confirmación"
                    value={fields.code}
                    onChange={e => setFields({...fields, code: e.target.value})}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Ingresa tu Password"
                    value={fields.password}
                    onChange={e => setFields({...fields, password: e.target.value})}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmPassword"
                    label="Ingresa tu Password Nuevamente"
                    value={fields.confirmPassword}
                    onChange={e => setFields({...fields, confirmPassword: e.target.value})}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleConfirmClick}
                    disabled={!validateResetForm()}
                >
                    Cambiar Password
                </Button>
            </form>
        </>);
    }

    function renderSuccessMessage() {
        return (
            <>
                Tu password ha sido cambiado exitosamente!
            </>
        )
    }

    return (
        <div className="Signup">
            {/*<Backdrop className={classes.backdrop} open={isLoading}>*/}
            <Backdrop className={classes.backdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" style={{color: '#333333'}}>
                        Cambia tu Contraseña
                    </Typography>
                    {!codeSent
                        ? renderRequestCodeForm()
                        : !confirmed
                            ? renderConfirmationForm()
                            : renderSuccessMessage()}
                </div>
                <Box mt={8}>
                    <Copyright/>
                </Box>
            </Container>
        </div>


    );
};