import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectFormerSchoolType = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;
    const formerSchoolType = [
        {value: 'PUBLICA', display: 'Pública'},
        {value: 'PRIVADA', display: 'Privada'},
    ];

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`formcontrol-select-major-${nameValue}`}
                id={`formcontrol-select-major-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {formerSchoolType.map((type, i) => <MenuItem value={type.value} key={`f-s-mi-${nameValue}-${i}`}>{type.display}</MenuItem>)}
            </Select>
        </FormControl>
    );
};