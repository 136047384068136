import React from "react";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import PrintIcon from '@material-ui/icons/Print';
import IconButton from "@material-ui/core/IconButton";
import config from "../../../config";

const createPdf = (rowData) => {
    const url = `${config.apiGateway.URL}/constancia70-pdf/${rowData.studentUuid}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
};

export function STUDENTS_PRINTS_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Nombre',
            field: 'name',
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
        },
        {
            title: 'Matrícula',
            field: 'studentId',
        },
        {
            title: 'Servicio Social 70%',
            field: '',
            render: rowData =>
                <IconButton aria-label="Imprimir Servicio Social Completo 70%">
                    <PrintIcon onClick={() => createPdf(rowData)}/>
                </IconButton>
        },
    ];
}
