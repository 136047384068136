import {API} from "aws-amplify";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {useEffect, useState} from 'react';
import {useStyles} from "./ModalChooseProfessorStyles";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {LOCALIZATION} from "../Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {MODAL_CHOOSE_GROUP_COLUMN_DEFINITION} from "../Material-TableCustomization/ColumnsDefinition/ModalChooseGroupColumnDefinition";

export default function ModalChooseGroup(props) {
    const classes = useStyles();
    const [groups, setGroups] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const loadGroupsApi = () => {
        return API.get('misServiciosEscolares', "/groups?type=basic");
    }

    const updateTopicApi = (payload) => {
        return API.put("misServiciosEscolares", `/topics?type=NOTINGROUPSTUDENTSUPDATE?action=ADD`, {
            body: payload
        });
    };

    const loadGroups = async () => {
        setIsLoading(true);
        try {
            const response = await loadGroupsApi();
            setGroups(response);
        } catch (e) {
            alert("Por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadGroups();
    }, []);

    // TODO: spinner
    const handleAssign = async () => {
        props.callbackClose(false);
        props.setIsLoading(true);
        try {
            const std = props.student;
            const payload = {
                studentUuid: std.studentUuid,
                topicId: `${props.selectedTopic.topicId}#${selectedGroups[0].groupId}`,
                groupId: selectedGroups[0].groupId,
                groupDescription: selectedGroups[0].name,
            }
            let response = await updateTopicApi(payload);
            props.callbackResponse(response);
        } catch (e) {
            console.log(e);
            window.alert("No se pudo asignar el grupo a la materia. Por favor intente de nuevo");
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <div>
            <Dialog
                open={props.openChooseGroupModal}
                onClose={() => props.callbackClose(false)}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                classes={{paper: classes.dialogPaper}}
            >
                <DialogContent>
                    <MaterialTable
                        data={groups}
                        isLoading={loading}
                        onSelectionChange={(rows) => setSelectedGroups(rows)}
                        columns={MODAL_CHOOSE_GROUP_COLUMN_DEFINITION(props.student?.major)}
                        title="Selecciona Grupo"
                        options={{
                            search: true,
                            paging: false,
                            selection: true
                        }}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        localization={LOCALIZATION('grupo', 'grupos')}
                        icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> props.callbackClose(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAssign} color="primary" disabled={selectedGroups.length !== 1}>
                        Asignar Grupo
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

