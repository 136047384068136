import React from "react";
import {green, red} from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

export function PROFESSORS_SIMULATION_COLUMN_DEFINITION(history) {
    return [
        {
            title: 'Clave',
            field: 'topicId',
            render: rowData =>
                <Button color='secondary'
                        onClick={() => {
                            console.log(history);
                            history.push(`/topics/${rowData.topicId.split('#')[0]}?groupId=${rowData.groupId}`)
                        }}
                        component={Link}>
                    {rowData.topicId.split('#')[0]}
                </Button>

        },
        {
            title: 'Materia',
            field: 'topicName',
        },
        {
            title: 'Carrera',
            field: 'groupMajor',
            render: rowData => <>{rowData.major}-{rowData.cuatrimestre}{rowData.group}</>
        },
        {
            title: 'Modo',
            field: 'mode',
        },
        {
            title: 'Ciclo',
            field: 'startingCycle',
        },
        {
            title: 'Entregado',
            field: 'topicHasBeenGraded',
            render: rowData => <span style={{ textAlign: "center" }}>{rowData.topicHasBeenGraded ?
                <CheckCircleIcon style={{ color: green[500] }}/> :
                <CancelIcon style={{ color: red[500] }}/>
            }</span>
        },
    ];
}