import {API} from "aws-amplify";
import "../Stylesheets/StudentDetails.css";
import React, {useState, useEffect} from "react";
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import StudentTableMetadata from "../../components/Details/Student/StudentTableMetadata";
import StudentDetailsTabsContainer from "../../components/Details/Student/StudentDetailsTabsContainer";

const useStyles = makeStyles((theme) => ({
    title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function StudentDetails(props) {
    const [studentDetails, setStudentDetails] = useState([]);
    const [studentGrades, setStudentGrades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState(null);
    const classes = useStyles();

    function renderLander() {
        return (
            <div className='lander'>
                <h1>Mis Servicios Escolares</h1>
                <p>Servicios escolares</p>
            </div>
        );
    }

    function loadStudentDetails() {
        return API.get("misServiciosEscolares", `/students/${props.match.params.id}`);
    }

    function loadStudentGrades() {
        return API.get("misServiciosEscolares", `/grades/${props.match.params.id}?fullDetails=true`);
    }

    async function onLoad(string) {
        setIsLoading(true);
        try {
            const studentDetailsResponse = await loadStudentDetails();
            setStudentDetails(studentDetailsResponse);
            setIsLoading(false);
            // Changed and now the response is an object and not an array of grades
            const studentGradesResponse = await loadStudentGrades();
            setStudentGrades(studentGradesResponse.grades ?? studentGradesResponse);
            setStudentDetails({
                ...studentDetailsResponse,
                average: studentGradesResponse.average,
                numberOfFailedTopics: studentGradesResponse.numberOfFailedTopics
            });
        } catch (e) {
            console.log(e);
            alert("No se pudo obtener la información del alumno. Por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    }

    const getCurrentUserInfo = async () => {
        const user = await API.Auth.currentAuthenticatedUser();
        setUsername(user?.username);
    }

    useEffect(() => {
        onLoad();
        getCurrentUserInfo();
    }, [props.match.params.id]);

    function renderGroup() {
        return (
            <div>
                <div className={classes.title}>
                    <StudentTableMetadata
                        studentDetails={studentDetails}
                        studentGrades={studentGrades}
                        history={props.history}
                    />
                </div>
                <div>
                    <StudentDetailsTabsContainer
                        student={studentDetails}
                        grades={studentGrades}
                        username={username}
                        history={props.history}/>
                </div>
            </div>
        );
    }

    return (
        <div className="StudentDetails">
            {props.isAuthenticated ? renderGroup() : renderLander()}
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
};