import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StatisticDetails from "./StatisticDetails";
import Button from '@material-ui/core/Button';
import StatisticsFailed from "./StatisticsFailed";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const StatisticsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    useEffect(() => {
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label="Estadísticas por Carrera" {...a11yProps(0)} />
                    <Tab label="Reprobados" {...a11yProps(1)} />
                    <Tab label="Documentos" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <StatisticDetails statistics={props.statistics}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <StatisticsFailed statistics={props.statistics}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                Documentos disponibles para estadísticos
                <div>
                    <Button color="primary">Estadística 911</Button>
                </div>
                <div>
                    <Button color="primary">Alumnos Agrupados por Materia y Sexo</Button>
                </div>
            </TabPanel>
        </div>
    );
};

export default StatisticsTabsContainer;