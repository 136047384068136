import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {red} from "@material-ui/core/colors";
import Link from "@material-ui/core/Link";

export const useStyles = makeStyles((theme) => ({
    failedStudents: {
        backgroundColor: red[100]
    }
}));


export default function StudentTableMetadata({studentDetails, history}) {
    const classes = useStyles();
    return (
        <div>
            {studentDetails ?
                <div>
                    <Typography variant="h4" component="h2">
                        ESTUDIANTE: {studentDetails?.name || ""}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        Matrícula: {studentDetails.studentId}
                    </Typography>
                    <Link
                        component="button"
                        onClick={() => history.push(`/groups/${studentDetails.groupInfo?.groupId}`)} variant="h5">
                        GRUPO: {studentDetails.major ?? "**"} -
                        {studentDetails.groupInfo?.cuatrimestre ?? "**"}
                        {studentDetails.groupInfo?.group ?? "**"}
                    </Link>
                    <Typography variant="h5" component="h2">
                        CURP: {studentDetails.curp}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        PROMEDIO: {studentDetails.average}
                    </Typography>
                    <Typography variant="h5" component="h2" className={classes.failedStudents}>
                        Materias Reprobadas: {studentDetails.numberOfFailedTopics}
                    </Typography>
                </div>
                : <></>}
        </div>
    );
}