import {API} from "aws-amplify";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {
    COGNITO_USERS_COLUMN_DEFINITION_PROPS
} from "../../components/Material-TableCustomization/ColumnsDefinition/CognitoUsersColumnDefinitions";
import DialogProfileSelection from "../../components/Modals/DialogProfileSelection";

export default function CognitoUsers(props) {
    const [cognitoUsers, setCognitoUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUserAttributes, setSelectedUserAttributes] = useState({});
    const [openProfileDialog, setOpenProfileDialog] = useState(false);

    const loadCognitoUsersApi = () => {
        return API.get('misServiciosEscolares', "/cognito/users");
    };

    const updateCognitoUserApi = (cognitoUserUuid) => {
        return API.put("misServiciosEscolares", `/cognito/users/${cognitoUserUuid}`, {});
    }

    const updateStatusCognitoUserApi = (cognitoUserUuid, currentStatus) => {
        return API.put("misServiciosEscolares", `/cognito/users/${cognitoUserUuid}`, {
            body: {currentStatus}
        });
    }

    const loadUsers = async () => {
        setIsLoading(true);
        try {
            const response = await loadCognitoUsersApi();
            setCognitoUsers(response);
        } catch (e) {
            alert("No se pudo obtener los profesores, por favor contacte al administrador: Ing. Carlos Ochoa 993 370 6587");
        } finally {
            setIsLoading(false);
        }
    }

    const onNameClickUpdatePassword = async (rowData) => {
        if (window.confirm(`Está seguro que desea cambiar la contraseña del usuario?`)) {
            setIsLoading(true);
            try {
                const response = await updateCognitoUserApi(rowData.Username);
                // await navigator.clipboard.writeText(response);
                alert(`Contraseña cambiada exitosamente! La nueva contraseña "${response}" ha sido copiada al portapapeles.`);
            } catch (e) {
                console.log(e.message);
                alert("No se pudo cambiar la contrasela del usuario, por favor contacte al administrador: Ing. Carlos Ochoa 993 370 6587");
            } finally {
                setIsLoading(false);
            }
        }
    }

    const disableUser = (rowData) => {
        if (rowData.Enabled) {
            if (window.confirm(`Está seguro que desea deshabilitar el usuario?`)) {
                window.alert(`Usuario deshabilitado exitosamente!`);
            }
        } else {
            if (window.confirm(`Está seguro que desea habilitar el usuario?`)) {
                window.alert(`Usuario habilitado exitosamente!`);
            }
        }
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadUsers();
    }, [props.isAuthenticated, props.match.params.id]);

    const changeUserProfile = (value) => {
        setOpenProfileDialog(true);
        setSelectedUserAttributes(value);
    }

    const renderProfessors = () => {
        return (
            <div>
                <DialogProfileSelection
                    openProfileDialog={openProfileDialog}
                    setOpenProfileDialog={setOpenProfileDialog}
                    selectedUserAttributes={selectedUserAttributes}
                    setIsLoading={setIsLoading}
                    loadUsers={loadUsers}
                />
                <MaterialTable
                    columns={COGNITO_USERS_COLUMN_DEFINITION_PROPS(props, onNameClickUpdatePassword, disableUser, changeUserProfile)}
                    data={cognitoUsers}
                    title="Usuarios Registrados en Plataforma"
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('usuario', 'usuarios')}
                    options={{
                        search: true,
                        pageSize: 20,
                        filtering: true,
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <CustomizedBackdrop open={false}/>
            {props.isAuthenticated ? renderProfessors() : renderLander()}
        </>
    );
};