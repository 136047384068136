import React from "react";
import {Container, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import {Rating} from "@material-ui/lab";
import PropTypes from "prop-types";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    ratingControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        padding: '0 15px'
    },
    commentControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
        // padding: '0 15px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        margin: '15px 0'
    }
}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentDissatisfiedIcon />,
        label: 'Dissatisfied',
    },
    3: {
        icon: <SentimentSatisfiedIcon />,
        label: 'Neutral',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon />,
        label: 'Satisfied',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon />,
        label: 'Very Satisfied',
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function Survey(props) {
    const classes = useStyles();
    const availableServices = [
        {value: "TESORERIA", display: "Tesorería"},
        {value: "ESCOLAR", display: "Servicios Escolares"},
    ];
    const [service, setService] = React.useState(availableServices[0].value);

    const handleChange = (event) => {
        setService(event.target.value);
    };

    return (
        <Container>
            <Typography variant="h4">
                Encuesta de Calidad de Servicio
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="mse-surve-service-input-label">Servicio Utilizado</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="mse-surve-service-select"
                            value={service}
                            fullWidth
                            onChange={handleChange}
                        >
                            {availableServices.map(o => (
                                <MenuItem value={o.value}>{o.display}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant="body1">
                        Cómo calificarías nuestros servicios?
                    </Typography>
                    <Rating name="size-large" defaultValue={2} size="large" />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField multiline rows={5} fullWidth label="Comentarios"/>
                </Grid>
                <Box width={1}>
                <Button variant="contained" color="secondary" fullWidth className={classes.submitButton}>Mandar Encuesta</Button>
                </Box>
            </Grid>
        </Container>
    );
}
