import React, {useEffect, useState} from "react";
import ModalChooseProfessor from "../../Modals/ModalChooseProfessor";
import {API} from "aws-amplify";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {green, red} from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import {Tooltip} from "@material-ui/core";
import ModalChooseSchedule from "../../Modals/ModalChooseSchedule";

export default function TopicDetailsMetadataTable({metadata, callback}) {
    const [professors, setProfessors] = useState([]);

    const loadProfessorsApi = () => {
        return API.get('misServiciosEscolares', "/professors");
    };

    const loadProfessors = async () => {
        await loadProfessorsApi()
            .then(response => setProfessors(response))
            .catch(e => {
                console.error(e);
            });
    }

    useEffect(() => {
        loadProfessors();
    }, [metadata]);

    const handleUpdate = (updateInProgress, error, cycle) => {
        callback(updateInProgress, error, cycle);
    };

    return (
        <div>
            {metadata ?
                <div>
                    <h3>
                        <b>{metadata.topicId ? metadata.topicId.split('#')[0] : <></>}: {metadata.topicName}</b>
                        <span style={{verticalAlign: '3px', paddingLeft: '5px'}}>
                            {metadata.topicHasBeenGraded ?
                                <Tooltip title="El curso ya fue evaluado">
                                    <CheckCircleIcon style={{color: green[500]}}/>
                                </Tooltip> :
                                <Tooltip title="El curso NO ha sido evaluado">
                                    <CancelIcon style={{color: red[500]}}/>
                                </Tooltip>
                            }
                        </span>
                    </h3>
                    <h5><b>Grupo: {metadata.groupMajor} - {metadata.cuatrimestre}{metadata.groupGroup}</b></h5>
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td className='details-key-subject'>Profesor Oficial</td>
                                <td className='detail-value-subject'>
                                    <ModalChooseProfessor
                                            data={{
                                                topicId: metadata.topicId,
                                                startingCycle: metadata.startingCycle
                                            }}
                                            labelButton={
                                                metadata.topicProfessorOfficial
                                                ? metadata.topicProfessorOfficial.name
                                                : "Asignar Profesor Oficial"
                                            }
                                            professorKey="topicProfessorOfficial"
                                            professors={professors}
                                            callback={handleUpdate}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='details-key-subject'>Profesor Interno</td>
                                <td className='detail-value-subject'>
                                    <ModalChooseProfessor
                                            data={{
                                                topicId: metadata.topicId,
                                                startingCycle: metadata.startingCycle
                                            }}
                                            labelButton={
                                                metadata.topicProfessorInternal
                                                ? metadata.topicProfessorInternal.name
                                                : "Assignar Profesor Interno"
                                            }
                                            professorKey="topicProfessorInternal"
                                            professors={professors}
                                            callback={handleUpdate}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='details-key-subject'>Fecha Límite Captura</td>
                                <td className='detail-value-subject'>
                                    <ModalChooseSchedule
                                        datelimit={metadata.dateLimit}
                                        callback={handleUpdate}
                                        topicId={metadata.topicId}/>
                                </td>
                            </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                : <></>}
        </div>
    );

}