import {API} from "aws-amplify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {useEffect, useState} from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {useStyles} from "./ModalChooseProfessorStyles";
import Paper from "@material-ui/core/Paper";
import {LOCALIZATION} from "../Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import MaterialTable from "material-table";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

export default function ModalChooseProfessor(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const updateTopicApi = (topicToBeUpdated) => {
        const payload = topicToBeUpdated?.topicProfessorInternal?.professorId
            ? {...topicToBeUpdated, topicProfessorInternalUuid: topicToBeUpdated.topicProfessorInternal?.professorId}
            : {...topicToBeUpdated, topicProfessorInternalUuid: "NA"};
        return API.put("misServiciosEscolares", `/topics`, {
            body: payload
        });
    };

    useEffect(() => {
    }, [props.professors]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAssign = async (isEmpty) => {
        props.callback(true);
        handleClose();
        const payload = !isEmpty
            ? {topicId: props.data.topicId, [props.professorKey]: selectedRows[0]}
            : { topicId: props.data.topicId, [props.professorKey]: ""};
        try {
            await updateTopicApi(payload);
            props.callback(false, false);
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
        }
    };

    return (
        <div>
            <Button color="primary" onClick={() => setOpen(true)}>
                {props.labelButton}
            </Button>
            <IconButton
                onClick={() => {if(window.confirm('Seguro que desea dejar este grupo sin profesor asignado?')){handleAssign(true)};}}
            >
                <DeleteIcon color="primary"/>
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle id="form-dialog-title">Selecciona un maestro</DialogTitle>
                <DialogContent>
                    <MaterialTable
                        title=""
                        data={props.professorKey === "topicProfessorOfficial"
                            ? props.professors ? props.professors.filter(o => o.type === "OFICIAL") : []
                            : props.professors ? props.professors.filter(o => o.type !== "OFICIAL") : []}
                        onSelectionChange={(rows) => setSelectedRows(rows)}
                        columns={[{title: 'Nombre del Profesor', field: 'name'}, {title: 'Tipo', field: 'type'}]}
                        options={{
                            search: true,
                            pageSize: 10,
                            selection: true,
                        }}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        localization={LOCALIZATION('profesor', 'profesores')}
                        icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleAssign(false)} color="primary" disabled={selectedRows.length !== 1}>
                        Asignar Maestro
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

