import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {statuses} from "../../CommonUtilities/UtilityFunctions";

export function GROUP_DETAILS_TABLE_STUDENTS_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Matrícula',
            field: 'studentId',
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.studentId}
                </Button>
        },
        {
            title: 'Nombre', field: 'name',
        },
        {
            title: 'Status',
            field: 'status',
            lookup: statuses(),
        }
    ];
}