import React from 'react';
import Button from "@material-ui/core/Button";
import config from "../../../config";

export default function TopicTableLists(props) {
    const K_PDF_PAGE = {
        PAGE_1: 'PAGE1',
        PAGE_2: 'PAGE2',
        PAGE_3: 'PAGE2',
    }

    const K_PDF_TYPE = {
        ATTENDANCE: 'ATTENDANCE',
        GRADES: 'GRADES',
    }

    const createPdf = (type, page) => {
        console.log(props.groupId);
        const url = `${config.apiGateway.URL}/pdf-groups/${props.groupId}?topicId=${props.topicId}&type=${type}&pageNumber=${page}`;

        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    };

    return (
        <>
            <div>
                Calificaciones
            </div>
            <div>
                <Button color="primary"
                        onClick={() => createPdf(K_PDF_TYPE.GRADES, K_PDF_PAGE.PAGE_1)}>
                    PDF Lista de Calificaciones Alumnos Página 1
                </Button>
            </div>
            {props.students > 20 ?
                <div>
                    <Button color="primary"
                            onClick={() => createPdf(K_PDF_TYPE.GRADES, K_PDF_PAGE.PAGE_2)}>
                        PDF Lista de Calificaciones Alumnos Página 2
                    </Button>
                </div> :
                <></>
            }
            {props.students > 50 ?
                <div>
                    <Button color="primary"
                            onClick={() => createPdf(K_PDF_TYPE.GRADES, K_PDF_PAGE.PAGE_3)}>
                        PDF Lista de Calificaciones Alumnos Página 3
                    </Button>
                </div> :
                <></>
            }
            <div>
                Asistencia
            </div>
            <div>
                <Button color="primary"
                        onClick={() => createPdf(K_PDF_TYPE.ATTENDANCE, K_PDF_PAGE.PAGE_1)}>
                    PDF Lista de Asistencia Alumnos Página 1
                </Button>
            </div>
            {props.students > 20 ?
                <div>
                    <Button color="primary"
                            onClick={() => createPdf(K_PDF_TYPE.ATTENDANCE, K_PDF_PAGE.PAGE_2)}>
                        PDF Lista de Asistencia Alumnos Página 2
                    </Button>
                </div> :
                <></>
            }
            {props.students > 50 ?
                <div>
                    <Button color="primary"
                            onClick={() => createPdf(K_PDF_TYPE.ATTENDANCE, K_PDF_PAGE.PAGE_3)}>
                        PDF Lista de Asistencia Alumnos Página 3
                    </Button>
                </div> :
                <></>
            }
        </>
    );
};