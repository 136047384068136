import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectStartingCycle = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;

    const cycles = [
        {value: "14-01", display: "14-01"},
        {value: "14-02", display: "14-02"},
        {value: "14-03", display: "14-03"},
        {value: "15-01", display: "15-01"},
        {value: "15-02", display: "15-02"},
        {value: "15-03", display: "15-03"},
        {value: "16-01", display: "16-01"},
        {value: "16-02", display: "16-02"},
        {value: "16-03", display: "16-03"},
        {value: "17-01", display: "17-01"},
        {value: "17-02", display: "17-02"},
        {value: "17-03", display: "17-03"},
        {value: "18-01", display: "18-01"},
        {value: "18-02", display: "18-02"},
        {value: "18-03", display: "18-03"},
        {value: "19-01", display: "19-01"},
        {value: "19-02", display: "19-02"},
        {value: "19-03", display: "19-03"},
        {value: "20-01", display: "20-01"},
        {value: "20-02", display: "20-02"},
        {value: "20-03", display: "20-03"},
        {value: "21-01", display: "21-01"},
        {value: "21-02", display: "21-02"},
        {value: "21-03", display: "21-03"},
        {value: "22-01", display: "22-01"},
        {value: "22-02", display: "22-02"},
        {value: "22-03", display: "22-03"},
        {value: "23-01", display: "23-01"},
        {value: "23-02", display: "23-02"},
        {value: "23-03", display: "23-03"},
        {value: "24-01", display: "24-01"},
        {value: "24-02", display: "24-02"},
        {value: "24-03", display: "24-03"},
        {value: "25-01", display: "25-01"},
        {value: "25-02", display: "25-02"},
        {value: "25-03", display: "25-03"},
    ];

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="startingDate-input">
                Primer Cuatrimestre (requerido)*
            </InputLabel>
            <Select
                labelId={`formcontrol-select-startingdate-${nameValue}`}
                id={`formcontrol-select-startingdate-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {cycles.map((cycle, i) => <MenuItem value={cycle.value} key={`f-s-mi-${nameValue}-${i}`}>{cycle.display}</MenuItem>)}
            </Select>
        </FormControl>
    );
};