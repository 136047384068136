import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectNationality = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;
    const nationalities = [
        {value: "MEXICO", display: 'Mexicano(a)'},
        {value: "ESTADOS UNIDOS", display: 'Estadounidense'},
        {value: "GUATEMALA", display: 'Guatemalteco(a)'},
        {value: "BELICE", display: 'Beliceño(a)'},
        {value: "HONDURAS", display: 'Hondureño(a)'},
        {value: "EL SALVADOR", display: 'Salvadoreño(a)'},
        {value: "OTRA NACIONALIDAD", display: 'Otra Nacionalidad'},
    ]

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`formcontrol-select-nationality-${nameValue}`}
                id={`formcontrol-select-nationality-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue,e)}
                required
                className={classes.FormControl}
            >
                {nationalities.map((country, i) => <MenuItem value={country.value} key={`f-s-mi-${nameValue}-${i}`}>{country.display}</MenuItem>)}
            </Select>
        </FormControl>
    );
};