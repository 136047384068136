import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomizedSnackbars(props) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.alertCallback({open: false, message: "", severity: ""});
  };

  return (
      <div className={classes.root}>
        <Snackbar
            open={props.status.open}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          <Alert
              onClose={handleClose}
              severity={props.status.severity}
          >
            {props.status.message}
          </Alert>
        </Snackbar>
      </div>
  );
}
