import {API} from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {useStyles} from "./TopicTableAttendanceStyles";
import TableContainer from "@material-ui/core/TableContainer";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {verifyPayments} from "../../CommonUtilities/UtilityFunctions";

export default function TopicTableAttendance(props) {
    const classes = useStyles();
    const sessions = [...Array(26).keys()];
    const [attendance, setAttendance] = useState(null);

    useEffect(() => {
        setAttendance(props.attendance.map(item => {return {...item, topicId: props.topicId, topicName: props.metadata.topicName}}));
    }, [props.attendance]);

    const TableHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>Matrícula</TableCell>
                    <TableCell>Nombre</TableCell>
                    {sessions.map(session => {
                        return <TableCell className={classes.header}>S{session+1}</TableCell>
                    })}
                </TableRow>
            </TableHead>
        );
    }

    const updateAttendanceApi = () => {
        return API.put("misServiciosEscolares", "/attendance", {
            body: attendance
        });
    };

    const updateAttendance = () => {
        props.callback(true);
        updateAttendanceApi()
            .then(() => props.callback(false, false))
            .catch(() => props.callback(false, true));
    };

    const handleUpdate = (session, value, index) => {
        setAttendance([
            ...attendance.slice(0, index),
            {
                ...attendance[index],
                [`s${session + 1}`]: value
            },
            ...attendance.slice(index + 1)
        ]);
    };

    return (
        <div>
            <Button onClick={updateAttendance} color='secondary' variant='contained'>Guardar cambios</Button>
            <div style={{margin: '10px 0 10px 0', textAlign: 'right'}}>
                <span><b>A = Asistencia</b></span>
                <span style={{marginLeft: '15px'}}><b>F = Falta</b></span>
                <span style={{marginLeft: '15px'}}><b>R = Retardo</b></span>
                <span style={{marginLeft: '15px'}}><b>J = Justificado</b></span>
            </div>
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHeader />
                    <TableBody>
                        {attendance ? attendance.map((row, index) => (
                            <TableRow key={row.studentId} style={{backgroundColor: verifyPayments(row.status) ? '#F08080' : 'clear'}}>
                                <TableCell component="th" scope="row">{row.studentId} </TableCell>
                                <TableCell>{row.name}</TableCell>
                                {sessions.map(session => {
                                    return <TableCell className={classes.columnWidth}>
                                        <FormControl className={classes.formControl}>
                                            <Select
                                                displayEmpty
                                                value={row[`s${session+1}`] ?? ''}
                                                onChange={e => handleUpdate(session, e.target.value, index)}
                                            >
                                                <MenuItem value="ASISTENCIA" disabled={verifyPayments(row.status)}>A</MenuItem>
                                                <MenuItem value="FALTA">F</MenuItem>
                                                <MenuItem value="RETARDO" disabled={verifyPayments(row.status)}>R</MenuItem>
                                                <MenuItem value="JUSTIFICADO" disabled={verifyPayments(row.status)}>J</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>;
                                })}
                            </TableRow>
                        )) : <></>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};