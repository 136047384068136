import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

export default function ProfessorTableMetadata({professorMetadata}) {
    const classes = useStyles();

    return (
        <>
            {professorMetadata ?
                <div className={classes.title}>
                    <div>
                        <Typography variant="h4" component="h2">
                            PROFESOR: {professorMetadata?.name ? professorMetadata.name : ""}
                        </Typography>
                    </div>
                    <div>
                        <Typography variant="h5" component="h2">
                            Email: {professorMetadata?.email ? professorMetadata.email : ""}
                        </Typography>
                    </div>
                </div>
                : <></>}
        </>
    );
}