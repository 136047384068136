import {API} from "aws-amplify";
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {LOCALIZATION_GLOBAL_ES} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {PROSPECTS_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/ProspectsColumnDefinition";

export default function Prospects(props) {
    const [prospects, setProspects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});
    const [callbackStatus, setCallbackStatus] = useState({
        newProspect: {},
        backdropOpen: false,
        alertStatus: {open: false, message: "", severity: ""}
    });

    const loadProspectsApi = () => {
        return API.get('misServiciosEscolares', "/prospects");
    };

    const updateProspectApi = (prospectToBeUpdated) => {
        return API.put("misServiciosEscolares", "/prospects", {
            body: prospectToBeUpdated
        });
    }

    const deleteProspectApi = (prospect) => {
        return API.del("misServiciosEscolares", `/prospects/${prospect.prospectId}`);
    }

    const loadProspects = async () => {
        try {
            const response = await loadProspectsApi();
            setProspects(response);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const updateProspectsAfterEdit = (prospectEdited) => {
        loadProspects().catch(error => setIsLoading(false));
        setAlertStatus({open: true, message: "El usuario ha sido modificado exitosamente", severity: "success"});
    }

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    }

    const handleUserAddedSuccessfully = (callbackStatus) => {
        setCallbackStatus(callbackStatus);
        setAlertStatus(callbackStatus.alertStatus);
        loadProspects().catch(error => setIsLoading(false));
    }

    const updateProspectAfterDeleteSuccess = (deletedProspect) => {
        setProspects(prospects.filter(prospect => prospect.prospectId !== deletedProspect.prospectId));
        setAlertStatus({open: true, message: "El profesor ha sido dado de baja definitivamente", severity: "success"});
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadProspects().catch(error => {
            setIsLoading(false);
        });
    }, [props.isAuthenticated, props.match.params.id]);

    const renderProspects = () => {
        return (
            <div className="Students">
                <MaterialTable
                    columns={PROSPECTS_COLUMN_DEFINITION}
                    data={prospects}
                    title="Prospectos UVT"
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={{
                        ...LOCALIZATION_GLOBAL_ES,
                        body: {
                            emptyDataSourceMessage: 'No hay prospectos que mostrar',
                            editRow: {
                                deleteText: 'Seguro que desea borrar este prospect?'
                            }
                        },
                    }}
                    options={{
                        search: true,
                        pageSize: 10,
                    }}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) =>
                    //         updateProspectApi(newData).then(response => {
                    //             updateProspectsAfterEdit(response);
                    //         }).catch(error =>
                    //             setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                    //         ),
                    //     onRowDelete: selectedProspect =>
                    //         deleteProspectApi(selectedProspect).then(
                    //             updateProspectAfterDeleteSuccess(selectedProspect)
                    //         ).catch( error =>
                    //             setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                    //         ),
                    // }}
                />
            </div>
        );
    }

    return (
        <div className="Prospects">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={callbackStatus.backdropOpen}/>
            {props.isAuthenticated ? renderProspects() : renderLander()}
        </div>
    );
};