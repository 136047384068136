import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectState = (props) => {
    const classes = useStyles();

    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;
    const estados = [
        'NO MEXICANO',
        'Aguascalientes',
        'Baja California',
        'Baja California Sur',
        'Campeche',
        'Chiapas',
        'Chihuahua',
        'Coahuila de Zaragoza',
        'Colima',
        'Ciudad de México',
        'Durango',
        'Guanajuato',
        'Guerrero',
        'Hidalgo',
        'Jalisco',
        'Estado de México',
        'Michoacan de Ocampo',
        'Morelos',
        'Nayarit',
        'Nuevo Leon',
        'Oaxaca',
        'Puebla',
        'Querétaro',
        'Quintana Roo',
        'San Luis Potosí',
        'Sinaloa',
        'Sonora',
        'Tabasco',
        'Tamaulipas',
        'Tlaxcala',
        'Veracruz',
        'Yucatan',
        'Zacatecas',
    ];

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={`formcontrol-inputlabel-${nameValue}`}>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`formcontrol-select-state-${nameValue}`}
                id={`formcontrol-select-state-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {estados.map((estado, i) => <MenuItem value={estado} key={`f-s-mi-${nameValue}-${i}`}>{estado}</MenuItem>)}
            </Select>
        </FormControl>
    );
};