import {API} from "aws-amplify";
import "../Stylesheets/GroupDetails.css";
import React, {useState, useEffect} from "react";
import {openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import StatisticsTabsContainer from "../../components/Details/Statistics/StatisticsTabsContainer";

export default function Statistics(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [statistics, setStatistics] = useState({});
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity:""});

    function renderLander() {
        return (
            <div className="lander">
                <h1>Mis Servicios Escolares</h1>
                <p>Servicios escolares</p>
            </div>
        );
    }

    const handleChangeOnAlert = (message, severity) => {
        openAlert(setAlertStatus, message, severity);
    };

    const loadStatistics = () => {
        return API.get("misServiciosEscolares", `/statistics`);
    };


    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }

        async function onLoad() {
            setIsLoading(true);
            try {
                const response = await loadStatistics();
                setStatistics(response);
                console.log(response, 'statistics');
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }

        onLoad();
    }, [props.isAuthenticated]);

    const presentBackdrop = (shouldItBePresented) => {
        setIsLoading(shouldItBePresented);
    }

    function renderGroup() {
        return (
            <div className="Statistics">
                <h3><b>Estadísticas UVT</b></h3>
                <div>
                    <StatisticsTabsContainer statistics={statistics}/>
                </div>
            </div>
        );
    }

    return (
        <div className="GroupDetails">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderGroup() : renderLander()}
        </div>
    );
};