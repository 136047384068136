import React from "react";
import {majors} from "../../CommonUtilities/UtilityFunctions";

export const STATISTICS_FAILED_COLUMN_DEFINITION = [
    {
        title: 'Matrícula',
        field: 'studentId',
        render: rowData => <a href={`/students/${rowData.studentUuid}`}>{rowData.studentId}</a>,
    },
    {
        title: 'Nombre',
        field: 'name'
    },
    {
        title: 'Carrera',
        field: 'major',
        lookup: majors(),
    },
    {
        title: 'Cuatrimestre',
        field: 'cuatrimestre',
        lookup:
            {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
            }
    },
    {
        title: 'Materias Reprobadas',
        field: 'numberOfFailedTopics',
        lookup:
            {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "5+": "5+",
            }
    },
];