import React from "react";
export const PROSPECTS_COLUMN_DEFINITION = [
    {
        title: 'Nombre',
        field: 'name',
        editable: 'never',
        render: rowData => <>{rowData.lastNameFather} {rowData.lastNameMother} {rowData.firstName} </>
    },
    {
        title: 'Ciclo',
        field: 'cycle',
    },
    {
        title: 'Carrera',
        field: 'major',
    },
    {
        title: 'Phone',
        field: 'Teléfono',
    },
    {
        title: 'Modo',
        field: 'mode',
        lookup:
            {
                "ESCOLARIZADO": "Escolarizado",
                "SABADOS": "Sábados",
                "DOMINGOS": "Domingos",
            }
    },

];