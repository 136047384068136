import {API} from "aws-amplify";
import React, {useEffect, useState} from 'react';
import MaterialTable from "material-table";
import {makeStyles} from '@material-ui/core/styles';
import ModalChooseStudent from "../../Modals/ModalChooseStudent";
import {LOCALIZATION_GLOBAL_ES} from "../../Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    GROUP_DETAILS_TABLE_STUDENTS_DEFINITION_PROPS
} from "../../Material-TableCustomization/ColumnsDefinition/GroupDetailsTableStudentsDefinition";
import {red} from "@material-ui/core/colors";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    actionsContainer: {
        marginBottom: "20px"
    },
    actionsModal: {
        display: "inline",
        margin: "20px 20px"
    }
});

export default function GroupTableStudents(props) {
    const classes = useStyles();
    const [students, setStudents] = useState([]);

    const deleteStudentForCurrentGroupApi = (student) => {
        return API.put("misServiciosEscolares", `/groups/${props.group.groupId}?action=DELETE_STUDENT`, {
            body: student
        });
    }

    const loadGroupStudentsApi = () => {
        return API.get("misServiciosEscolares", `/groups/${props.group.groupId}?details=GROUP_STUDENTS`);
    };

    const updateStudentApi = (updatedStudent) => {
        return API.put("misServiciosEscolares", "/students", {
            body: updatedStudent
        });
    }

    const addUsersCallback = (selectedRows) => {
        reloadGroupStudents();
    }

    const reloadGroupStudents = async () => {
        try {
            let response = await loadGroupStudentsApi();
            setStudents(response?.groupStudents);
        } catch (e) {
            alert("Los alumnos de este grupo no pudieron ser cargados, por favor refresque la página");
        }
    };

    const handleOnRowUpdate = async (updatedStudent, resolve, reject) => {
        try {
            await updateStudentApi(updatedStudent);
            await reloadGroupStudents();
        } catch (e) {
            alert('Por favor intente de nuevo');
            reject();
        } finally {
            resolve();
        }
    };

    const handleOnRowDelete = async (deletedStudent, resolve, reject) => {
        try {
            await deleteStudentForCurrentGroupApi(deletedStudent);
            props.callback(students.length - 1);
            const index = students.findIndex(o => o.studentUuid === deletedStudent.studentUuid);
            setStudents([
                ...students.slice(0, index),
                ...students.slice(index + 1)
            ]);
            resolve();
        } catch (e) {
            reject();
        }
    };

    useEffect(() => {
        setStudents(props.group.groupStudents);
    }, [props.group]);

    return (
        <div>
            <div className={classes.actionsContainer}>
                <ModalChooseStudent
                    students={props.students}
                    major={props.group.major}
                    labelButton={"Añadir Estudiante"}
                    callback={addUsersCallback}
                    backdropCallback={props.backdropCallback}
                    groupId={props.group.groupId}
                />
            </div>
            <MaterialTable
                title=""
                columns={GROUP_DETAILS_TABLE_STUDENTS_DEFINITION_PROPS(props)}
                data={students}
                options={{
                    search: true,
                    paging: false,
                    toolbar: false,
                    rowStyle: rowData => {
                        return {backgroundColor: rowData.status === "ACTIVO" ? "white" : red[100]};
                    }
                }}
                components={REMOVE_DROP_SHADOW}
                editable={{
                    onRowDelete: (newData) => new Promise((resolve, reject) => {
                        handleOnRowDelete(newData, resolve, reject);
                    }),
                    onRowUpdate: (newData) => new Promise((resolve, reject) => {
                        handleOnRowUpdate(newData, resolve, reject);
                    }),
                }}
                localization={{
                    ...LOCALIZATION_GLOBAL_ES,
                    body: {...LOCALIZATION_GLOBAL_ES.body, emptyDataSourceMessage: 'Este grupo no contiene alumnos',}
                }}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
            />
        </div>
    );
};