import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: "#3f51b5",
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
});