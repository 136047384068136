import React, {Fragment, useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Chip, Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '550px'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    Container: {
        padding: '0px 0px 50px 0px',
        textAlign: 'center'
    },
    Buttons: {
        margin: '20px'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function ProfessorDetailsTable({professorMetadata}) {
    const theme = useTheme();
    const classes = useStyles();
    const [personName, setPersonName] = React.useState([]);
    const [professor, setProfessor] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setProfessor(professorMetadata)
    }, [professorMetadata]);

    const handleChange = nameValue => ({target: {value}}) => {
        if (nameValue === 'associatedMajors') {
            setPersonName(value);
        } else {
            setProfessor({...professor, [nameValue]: value});
        }
    };

    function updateProfessorMetadataButton() {
        return (
            <Button variant="contained" color="primary" className={classes.Buttons}>
                Guardar Cambios
            </Button>
        )
    }

    const PROFESSOR_DETAILS_OPTIONS = {
        STATUSES: [
            {value: 'CONFIRMED', display: 'Confirmed'},
            {value: 'PENDING', display: 'Pending Approval'}
        ],
        TITLE: [
            {value: 'LICENCIADO', display: 'Licenciado'},
            {value: 'LICENCIADA', display: 'Licenciada'},
            {value: 'INGENIERO', display: 'Ingeniero'},
            {value: 'INGENIERA', display: 'Ingeniera'},
            {value: 'MAESTRO', display: 'Maestro'},
            {value: 'MAESTRA', display: 'Maestra'},
            {value: 'DOCTOR', display: 'Doctor'},
            {value: 'DOCTORA', display: 'Doctora'},
        ]
    };

    return (
        <div className={classes.Container}>
            {professorMetadata ?
                <div>
                    <Fragment>
                        <div>
                            <TextField
                                autoFocus={true}
                                fullWidth
                                margin="dense"
                                label="Nombre Completo"
                                value={professorMetadata.adminFullName}
                                onChange={() => handleChange('adminFullName')}
                                className={classes.FormControl}
                            />
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel>
                                    Status
                                </InputLabel>
                                <Select
                                    value={professorMetadata.status}
                                    onChange={() => handleChange('status')}
                                    className={classes.FormControl}
                                >
                                    {PROFESSOR_DETAILS_OPTIONS.STATUSES
                                        .map((status, i) =>
                                            <MenuItem
                                                value={status.value}
                                                key={`f-s-mi-${i}`}
                                            >
                                                {status.display}
                                            </MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Email"
                                value={professorMetadata.email}
                                onChange={() => handleChange('email')}
                                className={classes.FormControl}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Status"
                                value={professorMetadata.status}
                                onChange={() => handleChange('status')}
                                className={classes.FormControl}
                            />
                        </div>
                        <div>
                            <TextField
                                fullWidth
                                margin="dense"
                                label="Teléfono"
                                value={professorMetadata.phone}
                                onChange={() => handleChange('phone')}
                                className={classes.FormControl}
                            />
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <InputLabel>
                                    Título
                                </InputLabel>
                                <Select
                                    value={professorMetadata.title}
                                    onChange={() => handleChange('title')}
                                    className={classes.FormControl}
                                >
                                    {PROFESSOR_DETAILS_OPTIONS.TITLE
                                        .map((status, i) => <MenuItem value={status.value}
                                                                      key={`f-s-mi-${i}`}>{status.display}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.FormControl}>
                                <InputLabel id="demo-mutiple-chip-label">Chip</InputLabel>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    value={personName}
                                    onChange={() => handleChange('associatedMajors')}
                                    input={<Input id="associated-majors-select-multiple-chip"/>}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip}/>
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Fragment>
                    <div>
                        {updateProfessorMetadataButton()}
                    </div>
                </div> :
                <>
                    Leyendo información del sistema
                </>
            }


        </div>
    );
};