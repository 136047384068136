import {useEffect} from "react";
import {API} from "aws-amplify";
import React, {useState} from "react";
import Backdrop from "@material-ui/core/Backdrop";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    },
    details: {
        marginTop: theme.spacing(4),
    }
}));

const K_DEPARTMENT_TO_UUID = {
    COORDINACION_ACADEMICA: {name: "Coordinación Académica", uuid: "70e888dd-b201-43e9-bc3b-8fa42a4c0c50"},
    ENFERMERIA: {name: "Enfermería", uuid: "161e57e3-b84a-48ae-8ab2-bcc82b5f8ad2"},
    SERVICIOS_ESCOLARES: {name: "Servicios Escolares", uuid: "fd44aa9f-61f0-4de2-8f87-bb74cf586398"},
    CONTROL_ESCOLAR: {name: "Control Escolar", uuid: "5d6d4dc0-b21a-4c9c-95e5-f2e7bbc5ee6f"},
    FERNANDO: {name: "Fernando", uuid: "259cee3b-e554-44dc-a801-f7df556c181c"},
    LILIANA_ALVAREZ: {name: "Liliana Álvarez", uuid: "e5b3a7a2-3e8e-4c4a-8c4b-7c8d9f3f0b6f"},
}

export default function AttachmentsAccess(props) {
    const classes = useStyles();
    const [cognitoUsers, setCognitoUsers] = useState([]);
    const [department, setDepartment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);
    const [isAddingUserToDepartment, setIsAddingUserToDepartment] = useState(false);
    const [departmentCognitoUsers, setDepartmentCognitoUsers] = useState({});

    const loadUsersApi = () => {
        return API.get('misServiciosEscolares', "/cognito/users");
    };

    const loadCognitoUsers = async () => {
        setIsLoadingUsers(true);
        try {
            const response = await loadUsersApi();
            setCognitoUsers(response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoadingUsers(false);
        }
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadCognitoUsers();
    }, [props.isAuthenticated, props.username]);

    useEffect(() => {
        if (!department) {
            return;
        }
        getDepartmentCognitoUsers();
    }, [department]);


    const handleDepartmentChange = (event) => {
        setDepartment(event.target.value);
    };

    const addUsernameToDepartmentApi = (userUuid) => {
        if (!(department && userUuid)) {
            return;
        }

        const url = `/groups/${department}/attachments/access`;
        return API.put('misServiciosEscolares', url, {
            body: {
                username: userUuid,
                departmentUuid: department,
            }
        });
    }

    const addUsernameToDepartment = async (user) => {
        if (!department) {
            return;
        }
        const userFound = cognitoUsers.find(u => `${u?.email?.toLowerCase()} - ${u.name || ""}` === user);
        if (!userFound) {
            return;
        }
        if (departmentCognitoUsers[department].find(u => u.Username === userFound?.Username)) {
            alert('El usuario ya tiene acceso a los archivos del departamento');
            return;
        }
        const {Username} = userFound;
        setIsAddingUserToDepartment(true);
        setIsLoading(true);
        try {
            await addUsernameToDepartmentApi(Username);
            await getDepartmentCognitoUsers();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
            setIsAddingUserToDepartment(false);
        }
    };

    const getDepartmentCognitoUsersApi = () => {
        const url = `/groups/${department}/attachments/access`;
        return API.get('misServiciosEscolares', url);
    }

    const getDepartmentCognitoUsers = async () => {
        if (!department) {
            return;
        }
        setIsLoading(true);
        try {
            const response = await getDepartmentCognitoUsersApi();
            setDepartmentCognitoUsers({
                ...departmentCognitoUsers,
                [department]: response,
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteUsernameFromDepartmentApi = (username) => {
        if (!(department && username)) {
            return;
        }

        const url = `/groups/${department}/attachments/access/${username}`;
        return API.del('misServiciosEscolares', url);
    }

    const handleDeleteUserFromDepartment = async (user, index) => {
        const {Username: username = {}} = user;
        if (!(department && username)) {
            return;
        }
        setIsLoading(true);
        try {
            await deleteUsernameFromDepartmentApi(username);
            await getDepartmentCognitoUsers();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    function renderPrivileges() {
        return (
            <div className={classes.root}>
                <Typography variant="h4" component="h2">
                    Acceso a Archivos
                </Typography>
                <Typography variant="h5" component="h2">
                    Usuarios con acceso a archivos compartidos de la plataforma
                </Typography>
                <FormControl className={classes.formControl}>
                    <InputLabel id="choose-department-input-id">Departamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="choose-department-select"
                        value={department}
                        onChange={handleDepartmentChange}
                    >
                        {Object.keys(K_DEPARTMENT_TO_UUID).map((key) => {
                            return (
                                <MenuItem
                                    value={K_DEPARTMENT_TO_UUID[key].uuid}
                                >
                                    {K_DEPARTMENT_TO_UUID[key].name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {department &&
                    <div className={classes.details}>
                        <Autocomplete
                            freeSolo
                            id="free-solo-2-demo"
                            // onInputChange={(event, newPetInputValue) => addUsernameToDepartment(newPetInputValue)}
                            options={
                                cognitoUsers
                                    .filter(user => !departmentCognitoUsers[department]?.find(u => u.Username === user.Username))
                                    .map((user) => (`${user?.email?.toLowerCase()} - ${user.name || ""}`))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Añadir usuario"
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{...params.InputProps, type: 'buscar'}}
                                />
                            )}
                            onChange={(_event, newOptions, reason) => {
                                if (reason === "select-option") {
                                    addUsernameToDepartment(newOptions);
                                }
                            }}
                        />
                        <List className={classes.root}>
                            {department
                                && departmentCognitoUsers
                                && departmentCognitoUsers.hasOwnProperty(department)
                                && departmentCognitoUsers[department].map((user, index) => {
                                    const labelId = `checkbox-list-secondary-label-${user}`;
                                    return (
                                        <ListItem key={index} button>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={`Avatar n°${user + 1}`}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                id={labelId}
                                                primary={user.email}
                                                secondary={user.name}
                                            />
                                            <ListItemSecondaryAction
                                                onClick={() => handleDeleteUserFromDepartment(user)}
                                            >
                                                <IconButton edge="end" aria-label="delete" color={"secondary"}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </div>
                }
            </div>
        );
    }

    return (
        <>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            {props.isAuthenticated ? renderPrivileges() : props.history.push('/login')}
        </>
    );
};
