import "./App.css";
import Routes from "./Routes";
import {API, Auth} from "aws-amplify";
import {withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {createTheme, CssBaseline, Divider, Drawer, ThemeProvider} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import NavItems from "./navigation/NavItems";
import {useStyles} from "./navigation/NavStyles";
import {Copyright} from "./navigation/CopyRight";
import {TopBar} from "./navigation/TopBar";
import {blue, pink} from "@material-ui/core/colors";
import {UserContext} from "./libs/UserContext";

function App(props) {

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [currentUserInfo, setCurrentUserInfo] = useState(undefined);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [userAttachmentsAccess, setUserAttachmentsAccess] = useState(undefined);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const theme = createTheme({
        palette: {
            background: {
                default: isDarkMode ? '#424242' : 'white',
            },
            type: isDarkMode ? 'dark' : 'light',
            primary: {
                main: blue[800],
                background: {
                    default: "#e4f0e2"
                }
            },
            secondary: {
                main: isDarkMode ? pink[100] : pink[500],
            },
        },
    });

    function switchDarkMode() {
        setIsDarkMode(!isDarkMode);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    async function getAttachmentsAccessApi() {
        const url = `/groups/1/attachments/access/${currentUserInfo?.username}`;
        return API.get("misServiciosEscolares", url);
    }
    async function getAttachmentsAccess() {
        if (!currentUserInfo?.username) return;
        try {
            const response = await getAttachmentsAccessApi();
            setUserAttachmentsAccess(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
            const response = await Auth.currentUserInfo();
            setCurrentUserInfo(response);
            await getAttachmentsAccess();
        } catch (e) {
        }
        setIsAuthenticating(false);
    }

    async function handleLogout() {
        await Auth.signOut();
        userHasAuthenticated(false);
        setUserAttachmentsAccess(undefined);
        setCurrentUserInfo(undefined);
        props.history.push("/login");
    }

    function onClick(e, navigation) {
        e.preventDefault();
        props.history.push(navigation);
    }

    useEffect(() => {
        console.log(`UserHasAuthenticated: ${isAuthenticated}`);
        onLoad();
    }, [isAuthenticated]);

    useEffect(() => {
        getAttachmentsAccess();
    }, [currentUserInfo]);

    return (
        !isAuthenticating &&
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={{isAuthenticated, currentUserInfo}}>
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <TopBar
                            open={open}
                            classes={classes}
                            handleLogout={handleLogout}
                            isAuthenticated={isAuthenticated}
                            handleDrawerOpen={handleDrawerOpen}
                            switchDarkMode={switchDarkMode}
                        />
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider/>
                        <NavItems
                            onClick={onClick}
                            userAttachmentsAccess={userAttachmentsAccess}
                            currentUserInfo={currentUserInfo}
                        />
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer}/>
                        <Container maxWidth="lg" className={classes.container}>
                            <Routes appProps={{isAuthenticated, userHasAuthenticated, currentUserInfo}}/>
                            <Box pt={4}>
                                <Copyright/>
                            </Box>
                        </Container>
                    </main>
                </div>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default withRouter(App);