import React from "react";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

export function ATTACHMENTS_COLUMN_DEFINITION(onClick) {
    return [
        {
            title: 'Archivo',
            field: 'filename',
            render: rowData => <Link onClick={e => onClick(e, rowData)}>
                <Button variant="text" color="primary">
                    {rowData.filename}
                </Button>
            </Link>,
        },
        {
            title: 'Creado',
            field: 'isoDate',
        },
        {
            title: 'Creado Por',
            field: 'createdByEmail',
        }
    ];
};
