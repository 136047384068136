import React from "react";

export function MODAL_CHOOSE_GROUP_COLUMN_DEFINITION(defaultFilter) {
    return [
        {
            title: 'Grupo',
            field: 'name',
            editable: 'never',
            render: rowData => <>{rowData.major}-{rowData.cuatrimestre}{rowData.group}</>
        },
        {
            title: 'Modo',
            field: 'mode',
            editable: 'never',
            lookup:
                {
                    "SABADOS": "Sábados",
                    "DOMINGOS": "Domingos",
                    "ESCOLARIZADO": "Escolarizado",
                }
        },
        {
            title: 'Estudiantes',
            field: 'numberStudents',
            editable: 'never',
        },
        {
            title: "Carrera",
            field: "major",
            defaultFilter: defaultFilter
        }
    ];
};