import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TopicTableKardex from "./TopicTableKardex";
import TopicTableAttendance from "./TopicTableAttendance";
import TopicTableLists from "./TopicTableDocuments";
import TopicTableKardexExtraordinario from "./TopicTableKardexExtraordinario";
import TopicTableKardexDetailed from "./TopicTableKardexDetailed";
import TopicTableKardexExtraordinarioDetailed from "./TopicTableKardexExtraordinarioDetailed";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const TopicDetailsTabsContainer = props => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
    }, []);

    const validateAccessToExtraordinario = () => {
        if (props.metadata) {
            const withAccess = [
                "302caf93-a442-442f-8bd6-7a6da118b109", // aramis002@gmail.com
                "74f4576e-ca86-427c-ae19-1f610b4d0809", // camanan8@hotmail.com
                "a43f7995-8aa5-403f-b5b0-4025ce592374", // capturauvt@gmail.com
                props.metadata.topicProfessorInternalUuid
            ];
            return !(withAccess.includes(props.username));
        }
        return true;
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="Estudiantes">
                    <Tab label="Calificaciones" {...a11yProps(0)} />
                    <Tab label="Calificaciones Extraordinario" {...a11yProps(1)}
                         disabled={validateAccessToExtraordinario()}/>
                    <Tab label="Asistencia" {...a11yProps(2)} />
                    <Tab label="Documentos" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {
                    props.metadata?.groupMajor?.toUpperCase().includes("ENFERMERIA")
                    ? <TopicTableKardexDetailed
                        grades={props.grades}
                        topicId={props.topicId}
                        callback={props.callback}
                        groupId={props.metadata?.groupId}
                    />
                    : <TopicTableKardex
                        grades={props.grades}
                        topicId={props.topicId}
                        callback={props.callback}
                        groupId={props.metadata?.groupId}
                        topicHasBeenGraded={props.metadata?.topicHasBeenGraded}
                    />
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                {
                    props.metadata?.groupMajor?.toUpperCase().includes("ENFERMERIA")
                        ? <TopicTableKardexExtraordinarioDetailed
                            grades={props.grades}
                            topicId={props.topicId}
                            callback={props.callback}
                            groupId={props.metadata?.groupId}
                            topicHasBeenGraded={props.metadata?.topicHasBeenGraded}
                        />
                        : <TopicTableKardexExtraordinario
                            grades={props.grades}
                            topicId={props.topicId}
                            callback={props.callback}
                            groupId={props.metadata?.groupId}
                            topicHasBeenGraded={props.metadata?.topicHasBeenGraded}
                        />
                }

            </TabPanel>
            <TabPanel value={value} index={2}>
                <TopicTableAttendance
                    attendance={props.attendance}
                    callback={props.callback}
                    topicId={props.topicId}
                    metadata={props.metadata}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <TopicTableLists
                    groupId={props.metadata?.groupId}
                    topicId={props.topicId}
                    students={props.attendance?.length}/>
            </TabPanel>
        </div>
    );
};

export default TopicDetailsTabsContainer;
