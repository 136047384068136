import {API} from "aws-amplify";
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION,
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import {STUDENTS_DOCUMENTS_COLUMN_DEFINITION_PROPS} from "../../components/Material-TableCustomization/ColumnsDefinition/StudentsDocumentsColumnDefinition";

export default function Students(props) {
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBackdrop, setIsBackdrop] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadStudentsApi = () => {
        return API.get('misServiciosEscolares', "/students-documents");
    };

    const updateStudentApi = (updatedStudent) => {
        return API.put("misServiciosEscolares", "/students", {
            body: updatedStudent
        });
    }

    const loadStudents = async () => {
        try {
            const students = await loadStudentsApi();
            setStudents(students);
            setIsLoading(false);
            setIsBackdrop(false);
        } catch (e) {
            alert(e);
            setIsBackdrop(false);
        }
    };

    const updateStudentsAfterSuccessEdit = () => {
        loadStudents().finally(() => setIsBackdrop(false));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_UPDATE, K_ALERT.SEVERITY.SUCCESS)
    };

    const handleChangeOnAlert = shouldPresentAlert => {
        setAlertStatus(shouldPresentAlert);
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadStudents().finally(setIsLoading(false));
    }, [props.isAuthenticated]);

    function renderStudents() {
        // TODO: Update localization and put "Filtrar estudiantes"
        return (
            <div className="Students">
                <MaterialTable
                    columns={STUDENTS_DOCUMENTS_COLUMN_DEFINITION_PROPS(props)}
                    data={students}
                    title="Estudiantes Activos UVT"
                    isLoading={isLoading}
                    options={{
                        search: true,
                        pageSize: 20,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('estudiante', 'estudiantes')}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            updateStudentApi(newData).then(() => {
                                updateStudentsAfterSuccessEdit();
                            }).catch(() =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Students">
            <CustomizedBackdrop open={isBackdrop}/>
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderStudents() : renderLander()}
        </div>
    );
};


