import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import MenuRounded from "@material-ui/icons/MenuRounded";
import Typography from "@material-ui/core/Typography";
import config from "../config";
import {Toolbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Brightness4RoundedIcon from '@material-ui/icons/Brightness4Rounded';

export function TopBar({classes, open, handleDrawerOpen, isAuthenticated, handleLogout, switchDarkMode}) {
    return (
        <Toolbar className={classes.toolbar}>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
                <MenuRounded/>
            </IconButton>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
            >
                {`Admin UVT - ${config.env}`}
            </Typography>
            {/*<IconButton color="inherit">*/}
            {/*    <Badge badgeContent={1} color="secondary">*/}
            {/*        <NotificationsIcon/>*/}
            {/*    </Badge>*/}
            {/*</IconButton>*/}
            <IconButton
                color="inherit"
                onClick={switchDarkMode}
            >
                <Brightness4RoundedIcon/>
            </IconButton>
            {
                isAuthenticated
                    ? <Button color="inherit" onClick={handleLogout}>Cerrar Sesión</Button>
                    : <Button color="inherit">Login</Button>
            }
        </Toolbar>
    );
}