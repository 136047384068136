import {API} from "aws-amplify";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../Material-TableCustomization/Localization/LocalizationES";
import {majors} from "../CommonUtilities/UtilityFunctions";

const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '87vh',
        maxHeight: '87vh',
    },
    root: {
        width: "100%",
        overflowX: "auto"
    },
    actions: {
        display: "inline"
    },
});

export default function ModalChooseStudent(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const addStudentsForCurrentGroupApi = () => {
        return API.put("misServiciosEscolares", `/groups/${props.groupId}?action=ADD_STUDENT`, {
            body: selectedRows
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    // TODO: uncheck elements of the list
    const handleClose = () => {
        setOpen(false);
    };

    const handleAssign = async () => {
        handleClose();
        props.backdropCallback(true);
        await addStudentsForCurrentGroupApi()
            .then(() => props.callback(selectedRows))
            .finally(() => props.backdropCallback(false));
    }

    const currentMajor = () => {
        return [props.major];
    };

    return (
        <div className={classes.actions}>
            <div className={classes.actions}>
                <Button color="secondary" onClick={handleClickOpen} variant="contained">
                    {props.labelButton}
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle id="form-dialog-title">Selecciona un alumno</DialogTitle>
                <DialogContent>
                    <MaterialTable
                        title=""
                        data={props.students}
                        isLoading={!props.students?.length}
                        onSelectionChange={(rows) => setSelectedRows(rows)}
                        columns={[
                            {title: 'Matrícula', field: 'studentId'},
                            {title: 'Nombre', field: 'name'},
                            {title: 'Carrera', field: 'major', lookup: majors(), defaultFilter: currentMajor()},
                            {title: "Modalidad", field: 'mode', lookup:
                                    {
                                        "ESCOLARIZADO": "Escolarizado",
                                        "SABADOS": "Sábados",
                                        "DOMINGOS": "Domingos",
                                        "MIXTA": "Mixta",
                                    }},
                        ]}
                        options={{
                            search: true,
                            pageSize: 10,
                            selection: true,
                            filtering: true,
                        }}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        localization={LOCALIZATION('estudiante', 'estudiantes')}
                        icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleAssign} color="primary" disabled={selectedRows.length === 0}>
                        Añadir Estudiantes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};