import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import MaterialTable from "material-table";
import {GROUP_DETAILS_TABLE_SUBJECT_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/GroupDetailsTableSubjectDefinition";
import {API} from "aws-amplify";

export default function CollapsibleTable(props) {
    const [topics, setTopics] = useState([]);

    const updateTopicApi = (topicToBeUpdated) => {
        return API.put("misServiciosEscolares", `/topics/`, {
            body: topicToBeUpdated
        });
    };

    const handleOnRowUpdate = async (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            resolve();
        } else {
            try {
                const payload = {topicId: `${newData.topicId}#${newData.groupId}`, module: newData.module};
                let response = await updateTopicApi(payload);
                if (response?.topicId) {
                    const index = topics.findIndex(o => o.topicId === response.topicId.split('#')[0]);
                    setTopics([
                        ...topics.slice(0, index),
                        {...topics[index], module: response.module},
                        ...topics.slice(index + 1)
                    ]);
                }
                resolve();
            } catch (e) {
                alert('El grupo no fue actualizado, por favor intenta de nuevo');
                reject();
            }
        }
    };

    useEffect(() => {
        setTopics(props.group.topics);
    }, []);


    return (
        <div>
            <MaterialTable
                title=""
                data={topics}
                columns={GROUP_DETAILS_TABLE_SUBJECT_DEFINITION_PROPS(props)}
                options={{
                    search: true,
                    paging: false
                }}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                localization={LOCALIZATION('materia', 'materias')}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                editable={{
                    onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                        handleOnRowUpdate(newData, oldData, resolve, reject);
                    }),
                }}
            />
        </div>
    );
};