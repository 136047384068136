import {API} from "aws-amplify";
import "../Stylesheets/TopicDetails.css"
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import TopicDetailsMetadataTable from "../../components/Details/Topic/TopicDetailsMetadataTable";
import TopicDetailsTabsContainer from "../../components/Details/Topic/TopicDetailsTabsContainer";
import {Copyright} from "./Copyright";

export default function GroupDetails(props) {
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});
    const [attendance, setAttendance] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [grades, setGrades] = useState(null);
    const [username, setUsername] = useState(null);

    const loadTopicApi = () => {
        console.log("loadTopicApi");
        const path = `/topics/${props.match.params.id}${props.location.search}`;
        return API.get("misServiciosEscolares", path);
    };

    const loadTopic = async () => {
        console.log("loadTopic");
        setIsLoading(true);
        try {
            console.log("loadTopicApi");
            const response = await loadTopicApi();
            console.log(response);
            if (response.status === 404) {
                openAlert(setAlertStatus, "El grupo no fue encontrado", K_ALERT.SEVERITY.ERROR);
                return;
            }
            setGrades(response?.topicGroupGrades);
            setMetadata(response?.topicGroupMetadata);
            setAttendance(response?.topicGroupAttendance);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdate = (updateInProgress, error) => {
        if (updateInProgress) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
            if (error) {
                openAlert(setAlertStatus);
            } else {
                loadTopic();
                openAlert(setAlertStatus, K_ALERT.MESSAGE.GRADES.GRADES_UPDATED, K_ALERT.SEVERITY.SUCCESS);
            }
        }
    };

    const handleChangeOnAlert = (status) => {
        setAlertStatus(status);
    }

    const getCurrentUserInfo = async () => {
        const user = await API.Auth.currentAuthenticatedUser();
        setUsername(user?.username);
    }

    useEffect(() => {
        try {
            loadTopic();
            getCurrentUserInfo();
        } catch (e) {
            console.log(e);
        }

    }, [props.match.params.id, props.location.search]);

    function renderSubjects() {
        return (
            <div className="SubjectDetails">
                <div className="SubjectDetailsSection">
                    <TopicDetailsMetadataTable grades={grades} metadata={metadata} callback={handleUpdate}/>
                </div>
                <div>
                    <TopicDetailsTabsContainer
                        grades={grades}
                        topicId={props.match.params.id}
                        metadata={metadata}
                        attendance={attendance}
                        username={username}
                        callback={handleUpdate}/>
                </div>
            </div>
        );
    }

    return (
        <div className="GroupDetails">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderSubjects() : renderLander()}
            <Copyright />
        </div>
    );
};