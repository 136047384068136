const local = {
    apiGateway: {
        REGION: "us-east-1",
        URL: "http://localhost:4051/dev",
    },
    storage: {
        REGION: "us-east-1",
        BUCKET: "mse-dev-attachments-bucket"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_JTdWdCOcR",
        APP_CLIENT_ID: "gc6ebp5u9ilhep541rk4sgdv8",
        IDENTITY_POOL_ID: "us-east-1:607251c6-7b61-4f34-9719-55c606595661"
    },
    env: "LOCAL"
};

const dev = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_JTdWdCOcR",
        APP_CLIENT_ID: "gc6ebp5u9ilhep541rk4sgdv8",
        IDENTITY_POOL_ID: "us-east-1:607251c6-7b61-4f34-9719-55c606595661"
    },
    storage: {
        REGION: "us-east-1",
        BUCKET: "mse-dev-attachments-bucket"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://dev.api.misserviciosescolares.com",
    },
    env: "DEV"
}

const prod = {
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_vfJ8d7Qf2",
        APP_CLIENT_ID: "7dnqs9s05rs2uvonn7t6tm10kq",
        IDENTITY_POOL_ID: "us-east-1:671c9581-5711-46bb-8b79-a062a9524eb3"
    },
    storage: {
        REGION: "us-east-1",
        BUCKET: "mse-prod-attachments-bucket"
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://prod.api.misserviciosescolares.com",
    },
    env: "PROD"
};

let config = prod;

console.log(process.env.REACT_APP_STAGE, 'REACT_APP_STAGE =====> client <======');
if (process.env.REACT_APP_STAGE === 'local') {
    console.log('LOCAL');
    config = local;
}
if (process.env.REACT_APP_STAGE === 'dev') {
    console.log('DEV');
    config = dev;
}

console.log(process.env.REACT_APP_STAGE, 'REACT_APP_STAGE =====> client <======');

export default {
    ...(process.env.REACT_APP_STAGE === "local"
        ? local
        : process.env.REACT_APP_STAGE === 'dev'  ? dev : prod),
    MAX_ATTACHMENT_SIZE: 5000000
};