import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import MaterialTable from "material-table";
import {STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS} from "../../Material-TableCustomization/ColumnsDefinition/StudentPaymentsColumnDefinition";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";

export default function StudentPayments(props) {
    const [studentPayments, setStudentPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const loadStudentPaymentsApi = () => {
        return API.get("misServiciosEscolares", `/tesoreria/get-student-payments/${props.studentUuid}`);
    };

    const loadStudentPayments = async () => {
        setIsLoading(true);
        try {
            const studentPayments = await loadStudentPaymentsApi();
            setStudentPayments(studentPayments);
        } catch (e) {
            alert("No se pudo actualizar la lista de estudiantes. Por favor intente de nuevo.");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadStudentPayments();
    }, [props.studentUuid]);



    function renderStudentPayments() {
        return (
            <div>
                <MaterialTable
                    columns={STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS(props)}
                    data={studentPayments}
                    title="Pagos"
                    isLoading={isLoading}
                    options={{
                        pageSize: 10,
                        addRowPosition: 'first',
                        toolbar: false
                    }}
                    components={{...REMOVE_DROP_SHADOW,}}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('pago', 'pagos')}
                />
            </div>
        );
    }

    return (
        <>
            {renderStudentPayments()}
        </>
    );
};