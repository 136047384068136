import React from "react";
import {Link} from "@material-ui/core";
import Button from "@material-ui/core/Button";


export function GROUPS_COLUMN_DEFINITION_FAST(props) {
    return [
        {
            title: 'Grupo',
            field: 'rvoe',
            editable: 'never',
            render: rowData => <Button color='secondary' onClick={() => props.history.push(`/groups/${rowData.groupId}`)} component={Link}>
                {rowData.major}-{rowData.cuatrimestre}{rowData.group}
            </Button>
        },
        {
            title: 'Modo',
            field: 'mode',
            lookup:
                {
                    "SABADOS": "Sábados",
                    "DOMINGOS": "Domingos",
                    "ESCOLARIZADO": "Escolarizado",
                }
        },
        {
            title: 'Carrera',
            field: 'major',
            lookup:
                {
                    "ENFERMERIA": "Enfermería",
                    "ENFERMERIA_SEMESTRAL": "Enfermería Semestral",
                    "PSICOPEDAGOGIA": "Psicopedagogía",
                    "INDUSTRIAL": "Industrial",
                    "ADMINISTRACION": "Administración",
                    "PETROLERA": "Petrolera",
                    "DERECHO": "Derecho",
                    "AMBIENTAL": "Ambiental",
                    "MAESTRIA PETROLERA": "Maestría Petrolera",
                    "MAESTRIAPETROLERA": "Maestría Petrolera",
                    "MAESTRIA EDUCACION": "Maestría en Educación",
                    "MAESTRIAEDUCACION": "Maestría en Educación",
                },
            editable: 'never',
        },
        {
            title: 'Estudiantes',
            field: 'groupStudents',
            editable: 'never',
            render: rowData => <>{rowData?.numberStudents}</>,
        },
        {
            title: 'Cuatrimestre',
            field: 'cuatrimestre',
            editable: 'never',
            lookup:
                {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10": "10",
                }
        },
        {
            title: 'Grupo',
            field: 'group',
            lookup:
                {
                    "A": "A",
                    "B": "B",
                    "C": "C",
                    "D": "D",
                    "E": "E",
                }
        },
    ];
}
