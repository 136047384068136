import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {majors} from "../CommonUtilities/UtilityFunctions";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectMajor = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`formcontrol-select-major-${nameValue}`}
                id={`formcontrol-select-major-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {Object.keys(majors()).map((major, i) => {
                    return <MenuItem value={major} key={`f-s-mi-${nameValue}-${i}-${major}`}>{major}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
};