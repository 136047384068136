import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {API} from "aws-amplify";
import Button from "@material-ui/core/Button";

function StudentPortalDetails({studentUuid}) {
    const [alumnoMetadata, setAlumnoMetadata] = useState({});

    async function onLoad() {
        async function loadAlumnoMetadataApi() {
            return API.get("misServiciosEscolares", `/students/${studentUuid}/alumnos`);
        }

        try {
            const response = await loadAlumnoMetadataApi();
            setAlumnoMetadata(response);
        } catch (e) {
            console.log(e);
        }
    }

    async function createAlumnosAccount() {
        async function createAlumnosAccountApi() {
            return API.post("misServiciosEscolares", `/students/${studentUuid}/alumnos`);
        }

        try {
            const response = await createAlumnosAccountApi();
            setAlumnoMetadata(response);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        onLoad();
    }, [studentUuid]);

    return (
        <div>
            <Typography variant="h5" component="h2">
                Información Portal
            </Typography>
            <div style={{marginTop: '25px'}}>
                <div style={{marginBottom: '25px'}}>
                    <>
                        {
                            alumnoMetadata?.msaUuid
                                ?<Button
                                    onClick={createAlumnosAccount}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!alumnoMetadata?.msaUuid}
                                >
                                    Cambiar Password Portal de Alumnos
                                </Button>
                                :  <Button
                                    onClick={createAlumnosAccount}
                                    variant="contained"
                                    color="secondary"
                                    disabled={alumnoMetadata?.msaUuid}
                                >
                                    Crear Cuenta en Portal de Alumnos
                                </Button>
                        }

                    </>
                </div>
                <div>
                    UUID Portal Administrativo: {studentUuid}
                </div>
                {
                    alumnoMetadata &&
                    <div>
                        {
                            datasource.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {item.title}: {alumnoMetadata[item.objectKey]}
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div>
    );
}

const datasource = [
    {title: "UUID Portal Alumnos", objectKey: "msaUuid"},
    {title: "Username Portal Alumnos", objectKey: "username"},
    {title: "Password Portal Alumnos", objectKey: "password"},
]

export default StudentPortalDetails;