import {API} from "aws-amplify";
import '../Stylesheets/Professors.css'
import "../Stylesheets/Students.css";
import MaterialTable, {MTableToolbar} from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import Paper from "@material-ui/core/Paper";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PROFESSORS_SIMULATION_COLUMN_DEFINITION} from "../../components/Material-TableCustomization/ColumnsDefinition/ProfessorsSimulationColumnDefinition";

export default function Professors(props) {
    const [professors, setProfessors] = useState([]);
    const [topics, setTopics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadProfessorsApi = () => {
        return API.get('misServiciosEscolares', "/professors?details=basic");
    };

    const loadTopicsApi = (professorId) => {
        return API.get('misServiciosEscolares', `/topics?professorId=${professorId}`);
    };

    const loadProfessors = async () => {
        setIsLoading(true);
        try {
            const response = await loadProfessorsApi();
            setProfessors(response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadProfessors().finally(error => {
            setIsLoading(false);
        });
    }, [props.isAuthenticated, props.match.params.id]);

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    };

    async function userChangedSelectedProfessor(prof) {
        setIsLoading(true);
        try {
            const response = await loadTopicsApi(professors.filter(item => item.name === prof)[0].professorId);
            setTopics(response);
            console.log(response);
        } catch (e) {
            alert('Hubo un error al procesar su pedido');
        } finally {
            setIsLoading(false);
        }
    }

    const renderTeachers = () => {
        return (
            <div className="Professors">
                <MaterialTable
                    columns={PROFESSORS_SIMULATION_COLUMN_DEFINITION(props)}
                    data={topics}
                    title="Simulador de Profesores - Materias Asignadas"
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('materia', 'materias')}
                    options={{
                        search: true,
                        pageSize: 10,
                        filtering: true,
                    }}
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>,
                        Toolbar: props => (
                            <div>
                                <MTableToolbar {...props} />
                                <div style={{padding: '0px 10px'}}>
                                    <Autocomplete
                                        freeSolo
                                        onChange={(event, value) => userChangedSelectedProfessor(value)}
                                        id="Professors List"
                                        options={professors.map((professor) => professor.name)}
                                        style={{width: 400}}
                                        disableClearable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Seleccione un Profesor"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{...params.InputProps, type: 'search'}}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        ),
                    }}
                />
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderTeachers() : renderLander()}
        </div>
    );
};