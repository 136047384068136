import {API} from "aws-amplify";
import '../Stylesheets/Professors.css'
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {
    PROFESSORS_COLUMN_DEFINITION_PROPS
} from "../../components/Material-TableCustomization/ColumnsDefinition/ProfessorsColumnDefinition";
import ModalNewProfessor from "../../components/Modals/ModalNewProfessor";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";

export default function Professors(props) {
    const [professors, setProfessors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});
    const [callbackStatus, setCallbackStatus] = useState({
        newProfessor: {},
        backdropOpen: false,
        alertStatus: {open: false, message: "", severity: ""}
    });

    const loadProfessorsApi = () => {
        return API.get('misServiciosEscolares', "/professors");
    };

    const updateProfessorApi = (professorToBeUpdated) => {
        return API.put("misServiciosEscolares", "/professors", {
            body: professorToBeUpdated
        });
    }

    const handleOnRowUpdate = async (newData, oldData, resolve, reject) => {
        if (newData === oldData) {
            reject();
            return;
        }
        try {
            await updateProfessorApi(newData);
            const index = professors.findIndex(o => o.professorId === newData.professorId);
            setProfessors([
                ...professors.slice(0, index),
                newData,
                ...professors.slice(index + 1)
            ]);
            openAlert(setAlertStatus, K_ALERT.MESSAGE.PROFESSORS.PROFESSOR_UPDATE, K_ALERT.SEVERITY.SUCCESS);
        } catch (e) {
            openAlert(setAlertStatus, K_ALERT.MESSAGE.ERROR, K_ALERT.SEVERITY.ERROR);
        } finally {
            resolve();
        }
    }

    const deleteProfessorApi = (professor) => {
        return API.del("misServiciosEscolares", `/professors/${professor.professorId}`);
    }

    const loadProfessors = async () => {
        try {
            const response = await loadProfessorsApi();
            setProfessors(response);
            setIsLoading(false);
        } catch (e) {
            alert("No se pudo obtener los profesores, por favor contacte al administrador: Ing. Carlos Ochoa 993 370 6587");
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangeOnAlert = (shouldPresentAlert) => {
        setAlertStatus(shouldPresentAlert);
    }

    const updateProfessorsAfterAdd = (newProfessor) => {
        setProfessors([newProfessor, ...professors]);
    }

    const updateProfessorAfterDeleteSuccess = (deletedProfessor) => {
        setProfessors(professors.filter(professor => professor.professorId !== deletedProfessor.professorId));
        setAlertStatus({open: true, message: "El profesor ha sido dado de baja definitivamente", severity: "success"});
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadProfessors().catch(error => {
            setIsLoading(false);
        });
    }, [props.isAuthenticated, props.match.params.id]);

    const renderProfessors = () => {
        return (
            <div className="Professors">
                <MaterialTable
                    columns={PROFESSORS_COLUMN_DEFINITION_PROPS(props)}
                    data={professors}
                    title="Profesores UVT"
                    isLoading={isLoading}
                    components={REMOVE_DROP_SHADOW}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('profesor', 'profesores')}
                    options={{
                        search: true,
                        pageSize: 10,
                        filtering: true,
                    }}
                    editable={{
                        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                            handleOnRowUpdate(newData, oldData, resolve, reject);
                        }),
                        onRowDelete: selectedProfessor =>
                            deleteProfessorApi(selectedProfessor).then(
                                updateProfessorAfterDeleteSuccess(selectedProfessor)
                            ).catch( error =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                    }}
                />
                <ModalNewProfessor setIsLoading={setIsLoading} callback={updateProfessorsAfterAdd}/>
            </div>
        );
    }

    return (
        <div className="Professors">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={callbackStatus.backdropOpen}/>
            {props.isAuthenticated ? renderProfessors() : renderLander()}
        </div>
    );
};