import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    columnWidth: {
        maxWidth: "0px"
    },
    container: {
        boxShadow: 'none'
    },
    header: {
        textAlign: 'center'
    }
});