import {API} from "aws-amplify";
import {Checkbox, Chip, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ButtonMaterialUIProgress from "../../components/CommonUtilities/ButtonMaterialUIProgress";
import {AVAILABLE_ROLES, PRIVILEGES_OPTIONS} from "../../libs/Constants";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

export default function StudentDocumentsTable(props) {
    const classes = useStyles();
    const [privileges, setPrivileges] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [metadata, setMetadata] = useState({});

    const getPrivilegesApi = () => {
        return API.get("misServiciosEscolares", `/privileges/${props.match.params.id}`);
    }

    const updatePrivilegesApi = () => {
        return API.put("misServiciosEscolares", "/privileges/", {
            body: privileges
        });
    };

    const updatePrivileges = async () => {
        setIsLoading(true);
        try {
            await updatePrivilegesApi();
        } catch (e) {
            alert("No se pudieron actualizar los privilegios. Por favor contacte al Ing. Carlos Ochoa 993 370 6587");
        } finally {
            setIsLoading(false);
        }
    }

    const loadPrivileges = async () => {
        setIsLoading(true);
        try {
            const response = await getPrivilegesApi();
            setMetadata(response);
            setPrivileges(response);
        } catch (e) {
            alert("No se pudieron obtener los privilegios, por favor intente de nuevo");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadPrivileges();
    }, []);

    function setCustomPrivileges(role) {
        setPrivileges({...metadata, ...AVAILABLE_ROLES[role]});
    }

    const chipsInfo = [
        {role: "ADMIN", title: "Administrador"},
        {role: "PROFESSOR", title: "Professor"},
        {role: "TESORERIA", title: "Tesorería"},
        {role: "SES", title: "Servicios Escolares"},
        {role: "CLEAR", title: "De-seleccionar Todo"},
    ];

    return (
        <span>
            <div>
                <Typography variant="h3" component="h2">
                    Usuario: {privileges?.name ? privileges.name : ""}
                </Typography>
                <Typography variant="h4" component="h2">
                    Email: {privileges?.email ? privileges.email : ""}
                </Typography>
                <Typography variant="h4" component="h2">
                    Rol: {privileges?.role ? privileges.role : ""}
                </Typography>
            </div>
            <div>
                <div className={classes.root}>
                    <ButtonMaterialUIProgress text="Guardar Información" color='secondary' variant='contained'
                                              onClick={updatePrivileges} loading={isLoading}/>

                </div>
                <div className={classes.root}>
                    {chipsInfo.map(o => (
                        <Chip
                            label={o.title}
                            clickable
                            key={o.title}
                            color="primary"
                            onClick={e => setCustomPrivileges(o.role)}
                        />
                    ))}
                </div>
                <div>
                    <Grid item xs={12} md={12}>
                        {privileges?.userId
                            ? PRIVILEGES_OPTIONS.map((privilege, index) => {
                                return <div key={privilege.field}>
                                    <List dense>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={privileges[privilege.field] || false}
                                                    onChange={e => setPrivileges({
                                                        ...privileges,
                                                        [privilege.field]: e.target.checked
                                                    })}
                                                    inputProps={{'aria-label': 'primary checkbox'}}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={privilege.title}
                                                secondary={privilege.description}
                                            />
                                        </ListItem>
                                    </List>
                                </div>;
                            })
                            : <></>
                        }
                    </Grid>
                </div>
                <div className={classes.root}>
                    <ButtonMaterialUIProgress text="Guardar Información" color='secondary' variant='contained'
                                              onClick={updatePrivileges} loading={isLoading}/>

                </div>
            </div>
        </span>
    );
};