import Button from '@material-ui/core/Button';
import config from "../../config";
import React from 'react';

export default function ButtonGroupPDF(props) {
    const createPdf = () => {
        console.log(props.groupId);
        const url = `${config.apiGateway.URL}/pdf-groups/${props.groupId}?topicId=${props.topicId}`;

        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    return (
        <Button color="primary"
                onClick={createPdf}>
            PDF Lista de Alumnos
        </Button>
    );
};