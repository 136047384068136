export const K_PDF_TYPES = {
    KARDEX: "kardex",
    RECONOCIMIENTO: "reconocimiento",
    CONSTANCIA: "CONSTANCIAS",
};

export const UVT_STARTING_CYCLES = [
    "2006-01", "2006-02", "2006-03",
    "2007-01", "2007-02", "2007-03",
    "2008-01", "2008-02", "2008-03",
    "2009-01", "2009-02", "2009-03",
    "2010-01", "2010-02", "2010-03",
    "2011-01", "2011-02", "2011-03",
    "2012-01", "2012-02", "2012-03",
    "2013-01", "2013-02", "2013-03",
    "2014-01", "2014-02", "2014-03",
    "2015-01", "2015-02", "2015-03",
    "2016-01", "2016-02", "2016-03",
    "2017-01", "2017-02", "2017-03",
    "2018-01", "2018-02", "2018-03",
    "2019-01", "2019-02", "2019-03",
    "2020-01", "2020-02", "2020-03",
    "2021-01", "2021-02", "2021-03",
    "2022-01", "2022-02", "2022-03",
    "2023-01", "2023-02", "2023-03",
    "2024-01", "2024-02", "2024-03",
    "2025-01", "2025-02", "2025-03",
    "2026-01", "2026-02", "2026-03",
    "2027-01", "2027-02", "2027-03",
    "2028-01", "2028-02", "2028-03",
    "2029-01", "2029-02", "2029-03",
    "2030-01", "2030-02", "2030-03",
    "2031-01", "2031-02", "2031-03",
    "2032-01", "2032-02", "2032-03",
    "2033-01", "2033-02", "2033-03",
    "2034-01", "2034-02", "2034-03",
    "2035-01", "2035-02", "2035-03",
    "2036-01", "2036-02", "2036-03",
    "2037-01", "2037-02", "2037-03",
    "2038-01", "2038-02", "2038-03",
    "2039-01", "2039-02", "2039-03",
    "2040-01", "2040-02", "2040-03",
    "2041-01", "2041-02", "2041-03",
    "2042-01", "2042-02", "2042-03",
    "2043-01", "2043-02", "2043-03",
    "2044-01", "2044-02", "2044-03",
    "2045-01", "2045-02", "2045-03",
    "2046-01", "2046-02", "2046-03",
    "2047-01", "2047-02", "2047-03",
    "2048-01", "2048-02", "2048-03",
    "2049-01", "2049-02", "2049-03",
];

export const PRIVILEGES_OPTIONS = [
    {title: "Constancia 70% de cursos", field: "constancia70-pdf", type: "boolean", description: "Este permiso sirve para generar el PDF que acredita que el alumno ha completado 70% de sus cursos"},
    {title: "PDF de registro", field: "registration-pdf", type: "boolean", description: "Cuando un nuevo alumno es ingresado a la plataforma, se genera una forma de registro que es impresa para que el alumno la pueda firmar"},
    {title: "PDF de pago", field: "tesoreria-payment-pdf", type: "boolean", description: "Forma de pago generada por Tesorería cuando el alumno ha hecho un pago registrado en el sistema"},

    {title: "Crear grupo", field: "create-group", type: "boolean", description: "Capacidad para crear un nuevo alumno en el sistema de cualqueir carrera"},
    {title: "Crear pago", field: "create-payment", type: "boolean", description: "Crear pagos en el sistema. Únicamente gente con acceso a caja debe de tener este privilegio"},
    {title: "Crear profesor", field: "create-professor", type: "boolean", description: "Dar de alta a profesores internos y oficiales"},
    {title: "Crear estudiante", field: "create-student", type: "boolean", description: "Se utiliza este permiso para crear estudiantes en el sistema, ya sea de nuevo ingreso, o alumnos que vienen por equivalencias"},

    {title: "Borrar grupo", field: "delete-group", type: "boolean", description: "Se puede borrar un grupo. Una vez que el grupo se haya borrado, ya no se podrá recuperar. Su información existirá (calificaciones, alumnos, etc. pero tomará mucho tiempo recolectarla de nuevo"},
    {title: "Borrar pago", field: "delete-payment", type: "boolean", description: "Se podrán borrar los pagos del sistema. NOTA IMPORTANTE: este permiso es muy importante y solo debe de ser dado a administradores"},
    {title: "Borrar profesor", field: "delete-professor", type: "boolean", description: "Permisos para borrar un profesor completamente del sistema. Ya no se podrá asignar grupos al profesor, y si este profesor tiene materias asignadas, se verá que tiene un profesor indefinido. Se recomienda cambiar el estado del profesor en lugar de borrarlo."},
    {title: "Borrar estudiante", field: "delete-student", type: "boolean", description: "Se podrá borrar estudiantes del sistema. Todas las calificaciones/faltas e información asociada al estudiante se perderá. Se recomienda cambiar el status del estudiante en lugar de borrarlo."},

    {title: "Obtener calificaciones", field: "get-grades", type: "boolean", description: "Se podrán obtener calificaciones de un estudiante (no modificarlas)"},
    {title: "Obtener grupo", field: "get-group", type: "boolean", description: "Se pueden obtener los detalles de un grupo, sus alumnos, calificaciones, faltas, materias, fecha de creación, cuatrimestre, etc."},
    {title: "Obtener pago", field: "get-payment", type: "boolean", description: "Obtener toda la información acerca de un pago, fecha de cobro, método de pago, institución bancaria, etc."},
    {title: "Obtener PDF", field: "get-pdf", type: "boolean", description: ""},
    {title: "Obtener privilegios", field: "get-privileges", type: "boolean", description: "Se podrá obtener la lista de privilegios asociada a un usuario"},
    {title: "Obtener profesor", field: "get-professor", type: "boolean", description: "Privilegio necesario para obtener la información asociada a un profesor"},
    {title: "Obtener estadísticas", field: "get-statistics", type: "boolean", description: "No implementado por el momento"},
    {title: "Obtener estudiante", field: "get-student", type: "boolean", description: "Toda la información asociada a un alumno. Dirección, nombre completo, curp, y todos los demás metadatos encontrados en el sistema"},
    {title: "Obtener pagos de estudiante", field: "get-student-payments", type: "boolean", description: "Privilegio necesario para obtener toda la lista de pagos asociados a un estudiante"},
    {title: "Obtener materias", field: "get-topic", type: "boolean", description: "Obtener toda la información asociada a una materia, por ejemplo, a qué grupo pertenece, las calificaciones y asistencias de la materia, etc."},

    {title: "Listar grupos", field: "list-groups", type: "boolean", description: "Lista de grupos de toda la institución"},
    {title: "Listar pagos", field: "list-payments", type: "boolean", description: "Lista de pagos asociadas a los alumnos"},
    {title: "Listar privilegios", field: "list-privileges", type: "boolean", description: "Lista de todos los usuarios de la plataforma. Este privilegio es necesario si se quieren asignar privilegios"},
    {title: "Listar profesores", field: "list-professors", type: "boolean", description: "Listar profesores activos e inactivos. Se requiere si se necesita obtener la información de un profesor específico."},
    {title: "Listar estudiantes", field: "list-students", type: "boolean", description: "Listar estudiantes. Se necesita este permiso si se requiere modificar o añadir datos de un solo estudiante."},
    {title: "Listar documentos", field: "list-students-documents", type: "boolean", description: "Lista de alumnos y los documentos que se les han registrado en el sistema"},
    {title: "Listar materias", field: "list-topics", type: "boolean", description: "Lista de materias por grupo y toda la información asociada (grupo, ciclo, profesor interno, oficial)"},
    {title: "Listar resumen pagos", field: "list-summary-payments", type: "boolean", description: "Listar resumen de pagos del día"},

    {title: "Actualizar asistencia", field: "update-attendance", type: "boolean", description: "Capacidad de modificar las faltas de los alumnos (actuales y pasadas). Los profesores necesitan este permiso."},
    {title: "Actualizar calificaciones", field: "update-grades", type: "boolean", description: "Capacidad de modificar las calificaciones de los alumnos (actuales y pasadas). Los profesores necesitan este permiso."},
    {title: "Actualizar grupos", field: "update-group", type: "boolean", description: "Modificar datos del grupo, incluídos añadir y quitar alumnos de un grupo"},
    {title: "Actualizar privilegios", field: "update-privileges", type: "boolean", description: "Actualizar los privilegios de los usuarios del sistema. NOTA IMPORTANTE: si alguien tiene este privilegio, puede modificar y acceder a cualquier parte del sistema. Solo usuarios con todos los privilegios deben de tener este permiso."},
    {title: "Actualizar pagos", field: "update-payment", type: "boolean", description: "Actualizar pagos realizados por los alumnos"},
    {title: "Actualizar profesor", field: "update-professor", type: "boolean", description: "Actualizar metadatos de un profesor"},
    {title: "Actualizar estudiante", field: "update-student", type: "boolean", description: "Actualizar metadatos de un alumno"},
    {title: "Actualizar materia", field: "update-topic", type: "boolean", description: "Actualizar detalles de una materia, grupo al que pertenece, profesor (oficial e interno) asignado, etc."},

];


export const SCHOLARSHIPS = {
    "CEDAR_100": "CEDAR_100",
    "EF_380": "EF_380",
    "EF_480": "EF_480",
    "SETAB_50": "SETAB_50",
    "SITEM_10": "SITEM_10",
}

export const REGISTRATION_COMPONENT_TYPE = {
    NEW_USER: 'registration',
    UPDATE_USER: 'new'
}

export const PROFESSORS_TITLES = {
    "LICENCIADO": "Licenciado",
    "LICENCIADA": "Licenciada",
    "INGENIERO": "Ingeniero",
    "INGENIERA": "Ingeniera",
    "MAESTRO": "Maestro",
    "MAESTRA": "Maestra",
    "DOCTOR": "Doctor",
    "DOCTORA": "Doctora",
};

export const PROFESSOR_TYPES = {
    "OFICIAL": "OFICIAL",
    "INTERNO": "INTERNO",
}

export const AVAILABLE_ROLES = {
    ADMIN: {
        "constancia70-pdf": true,
        "registration-pdf": true,
        "tesoreria-payment-pdf": true,
        "create-group": true,
        "create-payment": true,
        "create-professor": true,
        "create-student": true,
        "delete-group": true,
        "delete-payment": true,
        "delete-professor": true,
        "delete-student": true,
        "get-grades": true,
        "get-group": true,
        "get-payment": true,
        "get-pdf": true,
        "get-privileges": true,
        "get-professor": true,
        "get-statistics": true,
        "get-student": true,
        "get-student-payments": true,
        "get-topic": true,
        "list-groups": true,
        "list-payments": true,
        "list-privileges": true,
        "list-professors": true,
        "list-students": true,
        "list-students-documents": true,
        "list-topics": true,
        "update-attendance": true,
        "update-grades": true,
        "update-group": true,
        "update-privileges": true,
        "update-payment": true,
        "update-professor": true,
        "update-student": true,
        "update-topic": true,
    },
    SES: {
        "constancia70-pdf": true,
        "registration-pdf": true,
        "create-group": true,
        "create-professor": true,
        "create-student": true,
        "get-grades": true,
        "get-group": true,
        "get-payment": true,
        "get-pdf": true,
        "get-privileges": true,
        "get-professor": true,
        "get-statistics": true,
        "get-student": true,
        "get-student-payments": true,
        "get-topic": true,
        "list-groups": true,
        "list-payments": true,
        "list-privileges": true,
        "list-professors": true,
        "list-students": true,
        "list-students-documents": true,
        "list-topics": true,
        "update-attendance": true,
        "update-grades": true,
        "update-group": true,
        "update-privileges": true,
        "update-professor": true,
        "update-student": true,
        "update-topic": true,
    },
    TESORERIA: {
        "tesoreria-payment-pdf": true,
        "create-payment": true,
        "get-grades": true,
        "get-group": true,
        "get-payment": true,
        "get-pdf": true,
        "get-professor": true,
        "get-student": true,
        "get-student-payments": true,
        "get-topic": true,
        "list-groups": true,
        "list-payments": true,
        "list-professors": true,
        "list-students": true,
        "list-students-documents": true,
        "list-topics": true,
        "update-professor": true,
        "update-student": true,
    },
    PROFESSOR: {
        "get-grades": true,
        "get-group": true,
        "get-payment": true,
        "get-pdf": true,
        "get-privileges": true,
        "get-professor": true,
        "get-statistics": true,
        "get-student": true,
        "get-student-payments": true,
        "get-topic": true,
        "list-groups": true,
        "list-professors": true,
        "list-students": true,
        "list-students-documents": true,
        "list-topics": true,
        "update-attendance": true,
        "update-grades": true,
    },
    CLEAR: {}
};