import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React, {Fragment, useState} from "react";
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import "./ModalNewProfessor.css"
import {API} from "aws-amplify";
import {Tooltip} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import {PROFESSORS_TITLES} from "../../libs/Constants";

const useStyles = makeStyles((theme) => ({
    FormControl: {
        width: 550
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

export default function ModalNewProfessor({setIsLoading, callback}) {
    const classes = useStyles();
    const professorDefault = {name: "", status: "ACTIVO", type: "OFICIAL", phone: ""}
    const [openModal, setOpenModal] = useState(false);
    const [professor, setProfessor] = useState(professorDefault);

    const createProfessor = () => {
        if (!professor.name || !professor.status) return;
        return API.post("misServiciosEscolares", "/professors", {
            body: professor
        });
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setOpenModal(false);
        try {
            const response = await createProfessor();
            callback(response);
        } catch (error) {
            alert("No se pudo crear al profesor. Por favor intente de nuevo o contacte al administrador");
        } finally {
            setProfessor({name: "", status: "ACTIVO", type: "INTERNO", phone: ""});
            setIsLoading(false);
        }
    };

    const clearStudentFormAndCloseModal = () => {
        setOpenModal(false);
        setProfessor(professorDefault);
    };

    return (
        <div className="ModalNewStudent">
            <Fragment>
                <Dialog open={openModal} onClose={clearStudentFormAndCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Crear profesor</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            required
                            fullWidth
                            margin="dense"
                            id="mnp-textfield-name"
                            label="Nombre del Profesor (requerido)"
                            value={professor.name}
                            onChange={e => setProfessor({...professor, name: e.target.value})}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="mnp-textfield-email"
                            label="Email (opcional)"
                            value={professor.email}
                            onChange={e => setProfessor({...professor, email: e.target.value})}
                            className={classes.FormControl}
                        />
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            id="mnp-textfield-phone"
                            label="Teléfono (opcional)"
                            value={professor.phone}
                            onChange={e => setProfessor({...professor, phone: e.target.value})}
                            className={classes.FormControl}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mnp-title-input-label">
                                Título*
                            </InputLabel>
                            <Select
                                labelId="mnp-title-label-id"
                                id="mnp-title-id"
                                value={professor.title}
                                onChange={e => setProfessor({...professor, title: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                {Object.keys(PROFESSORS_TITLES).map(title => {
                                    return <MenuItem value={title}>{title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mnp-status-input-label">
                                Estatus (requerido)*
                            </InputLabel>
                            <Select
                                labelId="mnp-status-label-id"
                                id="mnp-satus-id"
                                value={professor.status}
                                onChange={e => setProfessor({...professor, status: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ACTIVO"}>Activo</MenuItem>
                                <MenuItem value={"INACTIVO"}>Inactivo</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mnp-type-input-label">
                                Tipo (requerido)*
                            </InputLabel>
                            <Select
                                labelId="mnp-type-select"
                                id="mnp-type-id"
                                value={professor.type}
                                onChange={e => setProfessor({...professor, type: e.target.value})}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"INTERNO"} disabled={true}>Interno</MenuItem>
                                <MenuItem value={"OFICIAL"}>Oficial</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={clearStudentFormAndCloseModal}
                            color="primary"
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            disabled={(professor.name === "" || professor.status === "")}
                        >
                            Crear Profesor
                        </Button>
                    </DialogActions>
                </Dialog>
                <Fab color="primary" aria-label="add" className={classes.fab}>
                    <Tooltip title="Crear Estudiante" aria-label="add">
                        <Fab color="primary" className={classes.fab}>
                            <AddIcon onClick={() => setOpenModal(true)}/>
                        </Fab>
                    </Tooltip>
                </Fab>
            </Fragment>
        </div>
    );
};