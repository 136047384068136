import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function COGNITO_USERS_COLUMN_DEFINITION_PROPS(props, confirmChangePassword, confirmDisableUser, changeUserProfile) {
    return [
        {
            title: 'Email',
            field: 'email',
            render: rowData =>
                (<Button
                    color='secondary'
                    onClick={() => {confirmChangePassword(rowData)}}
                    component={Link}>
                    {rowData.email}
                </Button>)
        },
        {
            title: 'Nombre',
            field: 'name'
        },
        {
            title: 'Status',
            field: 'UserStatus',
            lookup: {
                "UNCONFIRMED": "Sin Confirmar",
                "CONFIRMED": "Verificado",
                "ARCHIVED": "Archivado",
                "COMPROMISED": "Comprometido",
                "UNKNOWN": "Desconocido",
                "RESET_REQUIRED": "Requiere Reset",
                "FORCE_CHANGE_PASSWORD": "Requiere Cambio de Contraseña"
            }
        },
        {
            title: 'Perfil',
            field: 'profile',
            lookup: {
                "MASTER": "Master",
                "ADMIN": "Administrador",
                "TESORERIA": "Tesorería",
                "PROFESSOR": "Profesor"
            },
            render: rowData =>
                (<Button
                    color='secondary'
                    onClick={() => changeUserProfile(rowData)}
                    component={Link}>
                    {rowData.profile}
                </Button>)
        },
        {
            title: 'Habilitado',
            field: 'Enabled',
            // render: rowData =>
            //     (<Button
            //         color='secondary'
            //         onClick={() => {confirmDisableUser(rowData)}}
            //         component={Link}>
            //         {rowData.Enabled ? "Sí" : "No"}
            //     </Button>)
        },
        {
            title: 'Verificado',
            field: 'email_verified',
            lookup: {
                "true": "Sí",
                "false": "No"
            }
        },

    ];
}