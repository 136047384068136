import {API} from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from 'react';
import TableRow from "@material-ui/core/TableRow";
import {useStyles} from "./TopicTableKardexStyles";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";


export default function TopicTableKardexExtraordinario(props) {
    const classes = useStyles();
    const [grades, setGrades] = useState([]);
    const [topicHasBeenGraded, setTopicHasBeenGraded] = useState(true);

    useEffect(() => {
        let tmp = props.topicHasBeenGraded ? props.topicHasBeenGraded : false;
        setTopicHasBeenGraded(tmp);
        setGrades(props.grades);
    }, [props.grades, props.topicId, props.groupId, props.topicHasBeenGraded]);

    const enableGroupToProfessorsApi = () => {
        return API.put("misServiciosEscolares", `/topics/`, {
            body: {
                topicHasBeenGraded: false,
                topicId: `${props.topicId}#${props.groupId}`,
            }
        });
    };

    const enableGroupToProfessors = async () => {
        props.callback(true);
        try {
            await enableGroupToProfessorsApi();
            props.callback(false, false);
            setTopicHasBeenGraded(false);
        } catch (e) {
            props.callback(false, true);
        }
    };


    const handleChange = (nameValue, index) => ({target: {value}}) => {
        let updatedGrade, final;
        updatedGrade = {
            desempenoExtra: parseFloat(grades[index]?.grade?.desempenoExtra || 0),
            actitudExtra: parseFloat(grades[index]?.grade?.actitudExtra || 0),
            productoExtra: parseFloat(grades[index]?.grade?.productoExtra || 0),
            conocimientoExtra: parseFloat(grades[index]?.grade?.conocimientoExtra || 0),
        };
        updatedGrade[nameValue] = parseFloat(value);
        final = updatedGrade?.desempenoExtra + updatedGrade?.actitudExtra + updatedGrade?.productoExtra + updatedGrade?.conocimientoExtra;

        if (final > 10) {
            alert('La calificación final no puede ser mayor a 10');
        } else {
            setGrades([
                ...grades.slice(0, index),
                {
                    ...grades[index],
                    grade: {
                        ...grades[index].grade,
                        extraordinario: `${final}`,
                        [nameValue]: value,
                    },
                }, ...grades.slice(index + 1)
            ]);
        }
    };

    const updateGradesApi = payload => {
        return API.put("misServiciosEscolares", "/grades", {
            body: payload
        });
    };

    function formatGradesForAPI() {
        let gradesPayload = {};
        grades.forEach(grade => {
            gradesPayload[grade.studentUuid] = {
                studentUuid: grade.studentUuid,
                studentId: grade.studentId,
                [props.topicId]: {
                    ...grade.grade,
                    topicId: props.topicId,
                },
            };
        });
        return gradesPayload;
    }

    const updateGrades = async () => {
        props.callback(true);
        const payload = {
            topicId: props.topicId,
            groupId: props.groupId,
            grades: formatGradesForAPI(),
        };
        try {
            await updateGradesApi(payload);
            props.callback(false, false);
        } catch (e) {
            props.callback(false, true);
        }
    };

    const verifyPayments = status => {
        return status === 'MOROSO';
    }

    const columnTitles = ["Matrícula", "Nombre", "Desemp", "Product", "Actitud", "Conocim", "Extraordinario", "Status"]
    const TableHeader = () => {
        return (
            <TableHead>
                <TableRow className={classes.tableHeaders}>
                    {columnTitles.map(title => (<TableCell>{title}</TableCell>))}
                </TableRow>
            </TableHead>
        );
    };

    return (
        <div>
            <Button
                color='secondary'
                variant='contained'
                onClick={updateGrades}>
                Guardar cambios
            </Button>
            <Button
                style={{margin: '0px 15px'}}
                color='primary'
                variant='contained'
                onClick={enableGroupToProfessors}
                disabled={!topicHasBeenGraded}>
                Habilitar Grupo a Profesores
            </Button>
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHeader/>
                    <TableBody>
                        {grades ? grades.map((row, index) => (
                            <TableRow key={index}
                                      style={{backgroundColor: verifyPayments(row.status) ? '#F08080' : 'clear'}}>
                                <TableCell component="th" scope="row">{row.studentId}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell><TextField
                                    value={row.grade?.desempenoExtra}
                                    type='number'
                                    step={0.1}
                                    onChange={handleChange('desempenoExtra', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.grade?.productoExtra}
                                    type='number'
                                    onChange={handleChange('productoExtra', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.grade?.actitudExtra}
                                    type='number'
                                    onChange={handleChange('actitudExtra', index)}
                                /></TableCell>
                                <TableCell><TextField
                                    value={row.grade?.conocimientoExtra}
                                    type='number'
                                    onChange={handleChange('conocimientoExtra', index)}
                                /></TableCell>
                                <TableCell>
                                    <TextField
                                        value={row.grade?.extraordinario}
                                        type='number'
                                        onChange={handleChange('extraordinario', index)}
                                    />
                                </TableCell>
                                <TableCell>
                                    {row.status}
                                </TableCell>
                            </TableRow>
                        )) : <></>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};