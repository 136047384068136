import React, {Fragment, useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {API} from "aws-amplify";
import {makeStyles} from "@material-ui/core/styles";
import {
    calculateCurrentCycleYYCC,
    MAJORS_MODES
} from "../../components/CommonUtilities/UtilityFunctions";
import {MSESelectState} from "../../components/CommonFields/MSESelectState";
import {MSESelectNationality} from "../../components/CommonFields/MSESelectNationality";
import {MSESelectStartingCycle} from "../../components/CommonFields/MSESelectStartingCycle";
import {MSESelectGroup} from "../../components/CommonFields/MSESelectGroup";
import {MSESelectGender} from "../../components/CommonFields/MSESelectGender";
import {MSESelectStatus} from "../../components/CommonFields/MSESelectStatus";
import {MSESelectMajor} from "../../components/CommonFields/MSESelectMajor";
import config from "../../config";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import {MSESelectFormerSchoolType} from "../../components/CommonFields/MSESelectFormerSchoolType";
import {MSESelectMaritalStatus} from "../../components/CommonFields/MSESelectMaritalStatus";
import {REGISTRATION_COMPONENT_TYPE} from "../../libs/Constants";

const useStyles = makeStyles({
    FormControl: {
        width: 550,
    },
    Container: {
        padding: '0px 0px 50px 0px',
        textAlign: 'center'
    },
    Buttons: {
        margin: '20px'
    }
});

export const createPdf = studentUuid => {
    const url = `${config.apiGateway.URL}/registration-pdf/${studentUuid}`;

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
};

export default function Registration(props) {
    const classes = useStyles();
    const defaultStudentState = {
        status: 'ACTIVO',
        plan: '2023',
        startingCycle: calculateCurrentCycleYYCC(),
    };
    const [student, setStudent] = useState(defaultStudentState);
    const [isLoading, setIsLoading] = useState(false);

    const createStudentApi = () => {
        student.majorType = student.major === "MAESTRIA EN EDUCACION" || student.major === "MAESTRIA PETROLERA"
            ? "MAESTRIA"
            : "LICENCIATURA";
        const payload = {
            firstName: student.firstName || '**',
            lastNameFather: student.lastNameFather || '**',
            lastNameMother: student.lastNameMother || '**',
            major: student.major || '**',
            status: student.status || '**',
            plan: student.plan || '**',
            startingCycle: student.startingCycle || '**',
            mode: student.mode || '**',
            subMode: student.subMode || '**',
            curp: student.curp || '**',
            address: student.address || '**',
            email: student.email || '**',
            phone: student.phone || '**',
            cellphone: student.cellphone || '**',
            referencePhone: student.referencePhone || '**',
            birthdayState: student.birthdayState || '**',
            referencePerson: student.referencePerson || '**',
            maritalStatus: student.maritalStatus || '**',
            group: student.group || '**',
            gender: student.gender || '**',
            nationality: student.nationality || '**',
            livingState: student.livingState || '**',
            birthday: student.birthday || '**',
            formerSchool: student.formerSchool || '**',
            formerSchoolEntity: student.formerSchoolEntity || '**',
            formerSchoolCity: student.formerSchoolCity || '**',
            formerSchoolType: student.formerSchoolType || '**',
            workName: student.workName || '**',
            workPhone: student.workPhone || '**',
            workAddress: student.workAddress || '**',
            workPosition: student.workPosition || '**',
            marketingMedium: student.marketingMedium || '**',
            marketingPlace: student.marketingPlace || '**',
        }
        if (props.type === REGISTRATION_COMPONENT_TYPE.UPDATE_USER) {
            console.log('Updating user');
            return API.put("misServiciosEscolares", "/students", {
                body: student
            });
        } else {
            return API.post("misServiciosEscolares", "/students", {
                body: payload
            });
        }
    };

    useEffect(() => {
        if (props.student) {
            setStudent(props.student);
        }
    }, [props.student]);

    const handleChange = nameValue => ({target: {value}}) => {
        setStudent({...student, [nameValue]: value});
    };

    const handleChangeExternal = (nameValue, e) => {
        setStudent({...student, [nameValue]: e.target.value});
    }

    const isFormComplete = () => {
        return (student.firstName && student.lastNameFather && student.lastNameMother && student.major && student.plan
            && student.status && student.startingCycle);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let response = await createStudentApi();
            if (props.type !== REGISTRATION_COMPONENT_TYPE.UPDATE_USER) {
                createPdf(response?.studentUuid);
                clearStudentForm();
            }
        } catch (e) {
            alert('No se pudo crear al alumno, por favor intente de nuevo');
        } finally {
            setIsLoading(false);
        }

    };



    const clearStudentForm = () => {
        setStudent(defaultStudentState);
    }

    function registrationButton() {
        return (
            <Button variant="contained" color="primary" className={classes.Buttons}
                    onClick={handleSubmit} disabled={!isFormComplete()}
            >
                {props.type === REGISTRATION_COMPONENT_TYPE.UPDATE_USER
                    ? <>Actualizar estudiante</>
                    : <>Registrar Nuevo Estudiante e Imprimir Registro</>
                }
            </Button>
        );
    }


    function renderRegistrationForm() {
        return (
            <div className={classes.Container}>
                <div>
                    {registrationButton()}
                </div>
                <Fragment>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Nombre(s) del Alumno (requerido)"
                            value={student.firstName || ''}
                            onChange={handleChange('firstName')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Paterno (requerido)"
                            value={student.lastNameFather || ''}
                            onChange={handleChange('lastNameFather')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Apellido Materno (requerido)"
                            value={student.lastNameMother || ''}
                            onChange={handleChange('lastNameMother')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <MSESelectMajor inputLabel="Carrera (requerido)*"
                                        value={student.major}
                                        nameValue="major"
                                        handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <MSESelectStatus
                            inputLabel="Estatus (requerido)*"
                            value={student.status}
                            nameValue="status"
                            handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="plan-input">
                                Plan (requerido)*
                            </InputLabel>
                            <Select
                                labelId="plan-select-label"
                                id="plan-select"
                                value={student.plan || ''}
                                onChange={handleChange('plan')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"2024"}>2024</MenuItem>
                                <MenuItem value={"2023"}>2023</MenuItem>
                                <MenuItem value={"2017"}>2017</MenuItem>
                                <MenuItem value={"2013"}>2013</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <MSESelectStartingCycle
                            inputLabel="Primer Cuatrimestre (requerido)*"
                            value={student.startingCycle}
                            nameValue="startingCycle"
                            handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mode-input">
                                Modalidad
                            </InputLabel>
                            <Select
                                labelId="mode-select-label"
                                id="mode-select"
                                value={student.mode || ''}
                                onChange={handleChange('mode')}
                                required
                                className={classes.FormControl}
                            >
                                {MAJORS_MODES[student.major]
                                    ? MAJORS_MODES[student.major]
                                        ? MAJORS_MODES[student.major].map(o => {
                                            return <MenuItem value={o}>{o}</MenuItem>
                                        })
                                        : <></>
                                    : <>SELECCIONA UNA CARRERA PRIMERO</>
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="mode-input">
                                Modalidad (interno)
                            </InputLabel>
                            <Select
                                labelId="mode-select-label"
                                id="mode-select"
                                value={student.subMode || ''}
                                onChange={handleChange('subMode')}
                                required
                                className={classes.FormControl}
                            >
                                <MenuItem value={"ESCOLARIZADO"}>Escolarizado</MenuItem>
                                <MenuItem value={"SABADOS"}>Sábados</MenuItem>
                                <MenuItem value={"DOMINGOS"}>Domingos</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="CURP"
                            value={student.curp || ''}
                            onChange={handleChange('curp')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 20 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Dirección actual"
                            value={student.address || ''}
                            onChange={handleChange('address')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 200 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Email"
                            type="email"
                            value={student.email || ''}
                            onChange={handleChange('email')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono particular"
                            value={student.phone || ''}
                            onChange={handleChange('phone')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 15 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono celular"
                            value={student.cellphone || ''}
                            onChange={handleChange('cellphone')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 15 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="En caso de emergencia llamar a"
                            value={student.referencePerson || ''}
                            onChange={handleChange('referencePerson')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono persona de contacto"
                            value={student.referencePhone || ''}
                            onChange={handleChange('referencePhone')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <MSESelectMaritalStatus inputLabel="Estado Civil"
                                                value={student.maritalStatus}
                                                nameValue="maritalStatus"
                                                handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <MSESelectGroup inputLabel="Grupo"
                                        value={student.group}
                                        nameValue="group"
                                        handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <MSESelectGender inputLabel="Sexo"
                                         value={student.gender}
                                         nameValue="gender"
                                         handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <MSESelectNationality inputLabel="Nacionalidad"
                                              value={student.nationality}
                                              nameValue="nationality"
                                              handleChange={handleChangeExternal}
                        />
                    </div>
                    <div>
                        <MSESelectState
                            inputLabel="Estado de Residencia"
                            handleChange={handleChangeExternal}
                            value={student.livingState}
                            nameValue='livingState'
                        />
                    </div>
                    <div>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="mse-birthday"
                                label="Fecha de nacimiento"
                                type="date"
                                onChange={handleChange('birthday')}
                                value={student.birthday}
                                className={classes.FormControl}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </div>
                    <div>
                        <MSESelectState
                            inputLabel="Estado de Nacimiento"
                            handleChange={handleChangeExternal}
                            value={student.birthdayState}
                            nameValue='birthdayState'
                        />
                    </div>
                    <div>
                        <TextField
                            required
                            fullWidth
                            margin="dense"
                            label="Escuela de Procedencia (requerido)"
                            value={student.formerSchool || ''}
                            onChange={handleChange('formerSchool')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 100 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Entidad donde cursó sus últimos estudios"
                            value={student.formerSchoolEntity || ''}
                            onChange={handleChange('formerSchoolEntity')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 50 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Municipio donde cursó sus últimos estudios"
                            value={student.formerSchoolCity || ''}
                            onChange={handleChange('formerSchoolCity')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 50 }}
                        />
                    </div>
                    <div>
                        <MSESelectFormerSchoolType
                            inputLabel="Tipo de Escuela de Procedencia"
                            handleChange={handleChangeExternal}
                            value={student.formerSchoolType}
                            nameValue='formerSchoolType'
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Empresa en la que Trabaja"
                            value={student.workName || ''}
                            onChange={handleChange('workName')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 45 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Teléfono de oficina"
                            value={student.workPhone || ''}
                            onChange={handleChange('workPhone')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 15 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Dirección de la empresa en la que trabaja"
                            value={student.workAddress || ''}
                            onChange={handleChange('workAddress')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 50 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Cargo o puesto"
                            inputProps={{ maxLength: 15 }}
                            value={student.workPosition || ''}
                            onChange={handleChange('workPosition')}
                            className={classes.FormControl}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="En dónde se enteró de la Universidad?"
                            value={student.marketingPlace || ''}
                            onChange={handleChange('marketingPlace')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 40 }}
                        />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            margin="dense"
                            label="Por qué medio solicitó requisitos?"
                            value={student.marketingMedium || ''}
                            onChange={handleChange('marketingMedium')}
                            className={classes.FormControl}
                            inputProps={{ maxLength: 40 }}
                        />
                    </div>
                </Fragment>
                <div>
                    {registrationButton()}
                </div>
            </div>
        );
    }

    return (
        <div className="Emails">
            <CustomizedBackdrop open={isLoading}/>
            {renderRegistrationForm()}
        </div>
    );
};
