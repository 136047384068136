import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    FormControl: {
        width: 550
    }
});

export const MSESelectMaritalStatus = (props) => {
    const classes = useStyles();
    const {
        inputLabel,
        label,
        value,
        handleChange,
        disabled,
        nameValue,
        ...others
    } = props;
    const statuses = [
        {value: 'SOLTERO(A)', display: 'Soltero(a)'},
        {value: 'CASADO(A)', display: 'Casado(a)'},
        {value: 'DIVORCIADO(A)', display: 'Divorciado(a)'},
        {value: 'VIUDO(A)', display: 'Viudo(a)'},
    ]

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>
                {inputLabel}
            </InputLabel>
            <Select
                labelId={`formcontrol-select-marital-status-${nameValue}`}
                id={`formcontrol-select-marital-status-${nameValue}`}
                value={value || ''}
                onChange={e => handleChange(nameValue, e)}
                required
                className={classes.FormControl}
            >
                {statuses.map((o, i) => <MenuItem value={o.value} key={`f-s-mi-${o.value}-${i}`}>{o.display}</MenuItem>)}
            </Select>
        </FormControl>
    );
};