import {
    PAYMENTS_CONCEPTS,
} from "../../CommonUtilities/UtilityFunctions";

export function STUDENT_PAYMENTS_COLUMN_DEFINITION_PROPS() {
    return [
        {
            title: "Concepto",
            field: "concept",
            lookup: PAYMENTS_CONCEPTS,
        },
        {
            title: "Fecha",
            field: "friendlyDate",
            type: "date",
        },
        {
            title: "Ref (opc)",
            field: "reference"
        },
        {
            title: "MES",
            field: "monthConcept",
            lookup: {
                "NO_APLICA": "NO_APLICA",
                0: "ENERO",
                1: "FEBRERO",
                2: "MARZO",
                3: "ABRIL",
                4: "MAYO",
                5: "JUNIO",
                6: "JULIO",
                7: "AGOSTO",
                8: "SEPTIEMBRE",
                9: "OCTUBRE",
                10: "NOVIEMBRE",
                11: "DICIEMBRE",
            }
        },
        {
            title: "Año",
            field: "yearConcept",
            lookup: {
                "2014": "2014",
                "2015": "2015",
                "2016": "2016",
                "2017": "2017",
                "2018": "2018",
                "2019": "2019",
                "2020": "2020",
                "2021": "2021",
                "2022": "2022",
                "2023": "2023",
                "2024": "2024",
                "2025": "2025",
                "2026": "2026",
            }
        },
        {
            title: "Recibido",
            field: "moneyTotal",
            type: "currency",
            editable: false
        },
        {
            title: "Precio",
            field: "price",
            type: "currency",
            editable: false
        },
        {
            title: "Descuento",
            field: "discount",
            type: "currency",
            editable: false,
        },
        {
            title: "Total",
            field: "total",
            type: "currency",
            editable: false
        }
    ];
}