import MaterialTable from "material-table";
import React from "react";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {LOCALIZATION_GLOBAL_ES} from "../../Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {STATISTICS_FAILED_COLUMN_DEFINITION} from "../../Material-TableCustomization/ColumnsDefinition/StatisticsFailedColumnDefinition";

export default function StatisticsFailed(props) {
    return (
        <div className="StatisticsFailed">
            <MaterialTable
                columns={STATISTICS_FAILED_COLUMN_DEFINITION}
                data={props.statistics}
                title="Estudiantes con Materias Reprobadas"
                components={REMOVE_DROP_SHADOW}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                localization={{
                    ...LOCALIZATION_GLOBAL_ES,
                    body: {
                        emptyDataSourceMessage: 'No hay alumnos que mostrar',
                    },
                }}
                options={{
                    search: true,
                    pageSize: 20,
                    filtering: true,
                }}
            />
        </div>
    );
};