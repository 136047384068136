import React from "react";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function PRIVILEGES_COLUMN_DEFINITION(props) {
    return [
        {
            title: "Nombre",
            field: "name",
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/privileges/${rowData.userId}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
        },
        {
            title: "Admin Name",
            field: "adminFullName",
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/privileges/${rowData.userId}`)}
                        component={Link}>
                    {rowData.adminFullName}
                </Button>,
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Rol",
            field: "role",
            lookup:
                {
                    "ADMIN": "Administrador",
                    "PROFESSOR": "Profesor",
                },
        },
    ];
}