import {API} from "aws-amplify";
import MaterialTable from "material-table";
import GroupIcon from '@material-ui/icons/Group';
import React, {forwardRef, useEffect, useState} from 'react';
import ModalChooseGroup from "../../Modals/ModalChooseGroup";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {STUDENTS_GRADES_TABLE_COLUMN_DEFINITION} from "../../Material-TableCustomization/ColumnsDefinition/StudentGradesTableColumnDefinition";
import {red} from "@material-ui/core/colors";
import {
    ENFERMERIA_STUDENTS_GRADES_TABLE_COLUMN_DEFINITION
} from "../../Material-TableCustomization/ColumnsDefinition/EnfermeriaStudentGradesTableColumnDefinition";

export default function StudentGradesTable(props) {
    const [grades, setGrades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState([]);
    const [openChooseGroupModal, setOpenChooseGroupModal] = useState(false);
    const tableIcons = {
        RemoveIcon: forwardRef((props, ref) => <RemoveCircleOutlineIcon color="secondary" {...props} ref={ref}/>),
        GroupIcon: forwardRef((props, ref) => <GroupIcon color="secondary" {...props} ref={ref}/>)
    };

    useEffect(() => {
        loadGrades();
    }, []);

    const updateEnfermeriaStudentGradeApi = newGrade => {
        const url = `/enfermeria/${newGrade?.topicId}/grades/${props.student?.studentUuid}`;
        return API.put("misServiciosEscolares", url, {
            body: newGrade
        });
    };

    function loadStudentGradesApi() {
        return API.get("misServiciosEscolares", `/grades/${props.student?.studentUuid}?fullDetails=true`);
    }

    const loadGrades = async () => {
        setIsLoading(true);
        try {
            const response = await loadStudentGradesApi();
            setGrades(response.grades ?? response);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const updateStudentGradeApi = (updatedStudentGrade, action) => {
        const payload = {
            studentUuid: props.student.studentUuid,
            topicId: updatedStudentGrade.topicId,
            grades: updatedStudentGrade
        };
        const actionParam = action ? action : "ADD";
        return API.put("misServiciosEscolares", `/grades?type=single&action=${actionParam}`, {
            body: payload
        });
    }

    // TODO: Refactor all the onRowUpdate to this format
    const handleOnRowUpdate = async (updatedStudent, resolve, reject) => {
        try {
            if (props.student?.major.toUpperCase().includes("ENFERMERIA")) {
                await updateEnfermeriaStudentGradeApi(updatedStudent);
            } else {
                await updateStudentGradeApi(updatedStudent);
            }
            await loadGrades();
            // let index = grades.findIndex(o => o.topicId === responseUpdate.topicId);
            // setGrades([
            //     ...grades.slice(0, index),
            //     responseUpdate,
            //     ...grades.slice(index + 1)
            // ]);
        } catch (e) {
            alert('Por favor intente de nuevo');
            reject();
        } finally {
            resolve();
        }
    };

    function handleOnRowAdd(newItem) {
        let index = grades.findIndex(o => o.topicId === newItem.topicId);
        setGrades([
            ...grades.slice(0, index),
            {...grades[index], externalGroupId: newItem.externalGroupId},
            ...grades.slice(index + 1)
        ]);
    }


    return (
        <div>
            <ModalChooseGroup
                openChooseGroupModal={openChooseGroupModal}
                callbackClose={setOpenChooseGroupModal}
                setIsLoading={setIsLoading}
                selectedTopic={selectedRow} student={props.student} callbackResponse={handleOnRowAdd}
            />
            <MaterialTable
                columns={
                    props.student?.major.toUpperCase().includes("ENFERMERIA")
                        ? ENFERMERIA_STUDENTS_GRADES_TABLE_COLUMN_DEFINITION(props)
                        : STUDENTS_GRADES_TABLE_COLUMN_DEFINITION(props)
                }
                data={grades}
                title="Calificaciones"
                isLoading={isLoading}
                components={REMOVE_DROP_SHADOW}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                localization={LOCALIZATION('materia', 'materias')}
                options={{
                    paging: false,
                    rowStyle: rowData => {
                        return {backgroundColor: rowData.failed ? red[100] : '#FFF'};
                    }
                }}
                // TODO: Change all the updates to this format
                editable={{
                    onRowUpdate: (newData) => new Promise((resolve, reject) => {
                        handleOnRowUpdate(newData, resolve, reject);
                    }),
                }}
                actions={[
                    {
                        icon: tableIcons.GroupIcon,
                        onClick: (event, rowData) => {
                            console.log(rowData);
                            setSelectedRow(rowData);
                            setOpenChooseGroupModal(true);
                        }
                    },
                ]}
            />
        </div>
    );
};