import {API} from "aws-amplify";
import "../Stylesheets/Students.css";
import MaterialTable from "material-table";
import React, {useState, useEffect} from "react";
import ModalNewStudent from "../../components/Modals/ModalNewStudent";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    LOCALIZATION,
} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import {ICONS_OVERRIDE_SECONDARY_COLOR} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import {K_ALERT, openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import {
    STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS
} from "../../components/Material-TableCustomization/ColumnsDefinition/StudentsLightColumnDefinition";
import {createPdf} from "./Registration";

export default function Students(props) {
    const [students, setStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isBackdrop, setIsBackdrop] = useState(true);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity: ""});

    const loadStudentsApi = () => {
        return API.get('misServiciosEscolares', "/students?details=basic&active=true");
    };


    const deleteStudentApi = (targetUser) => {
        return API.del("misServiciosEscolares", `/students/${targetUser.studentUuid}`);
    };

    const updateStudentApi = (updatedStudent) => {
        return API.put("misServiciosEscolares", "/students", {
            body: updatedStudent
        });
    }

    const handleCreateStudentComponentsAsync = (isBackdrop, studentCreated) => {
        setIsBackdrop(isBackdrop);
        if (studentCreated) {
            if (studentCreated !== 'PENDING') {
                updateStudentsAfterSuccessEdit();
            }
        } else {
            openAlert(setAlertStatus);
        }
    }

    const loadStudents = async () => {
        try {
            const students = await loadStudentsApi();
            setStudents(students);
            setIsLoading(false);
            setIsBackdrop(false);
        } catch (e) {
            alert(e);
            setIsBackdrop(false);
        }
    };

    const updateStudentsAfterSuccessDelete = deletedStudent => {
        setStudents(students.filter(student => student.studentId !== deletedStudent.studentId));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_DELETE, K_ALERT.SEVERITY.SUCCESS)
    };

    const updateStudentsAfterSuccessEdit = () => {
        loadStudents().finally(() => setIsBackdrop(false));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_UPDATE, K_ALERT.SEVERITY.SUCCESS)
    };

    const handleChangeOnAlert = shouldPresentAlert => {
        setAlertStatus(shouldPresentAlert);
    }

    const updateStudentsAfterSuccessCreate = () => {
        loadStudents().finally(() => setIsBackdrop(false));
        openAlert(setAlertStatus, K_ALERT.MESSAGE.STUDENTS.STUDENT_CREATE, K_ALERT.SEVERITY.SUCCESS)
    };

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }
        loadStudents().finally(setIsLoading(false));
    }, [props.isAuthenticated]);

    function renderStudents() {
        // TODO: Update localization and put "Filtrar estudiantes"
        return (
            <div className="Students">
                <MaterialTable
                    columns={STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS(props)}
                    data={students}
                    title="Estudiantes UVT"
                    isLoading={isLoading}
                    options={{
                        search: true,
                        filtering: true,
                        pageSize: 20,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    actions={[
                        {
                            icon: 'print',
                            tooltip: 'Imprimir constancia',
                            onClick: (event, rowData) => {
                                createPdf(rowData.studentUuid);
                            }
                        }
                    ]}
                    localization={LOCALIZATION('estudiante', 'estudiantes')}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            updateStudentApi(newData).then(() => {
                                updateStudentsAfterSuccessEdit();
                            }).catch(() =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                        onRowDelete: selectedStudent =>
                            deleteStudentApi(selectedStudent).then(
                                updateStudentsAfterSuccessDelete(selectedStudent)
                            ).catch(() =>
                                setAlertStatus({open: true, message: "Por favor intenta de nuevo", severity: "error"})
                            ),
                    }}
                />
                <ModalNewStudent
                    onCreate={updateStudentsAfterSuccessCreate}
                    callback={handleCreateStudentComponentsAsync}/>
            </div>
        );
    }

    return (
        <div className="Students">
            <CustomizedBackdrop open={isBackdrop}/>
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            {props.isAuthenticated ? renderStudents() : props.history.push("/login")}
        </div>
    );
};


