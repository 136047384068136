import {API} from "aws-amplify";
import "../Stylesheets/GroupDetails.css";
import React, {useState, useEffect} from "react";
import {renderLander} from "../../components/CommonUtilities/RenderLander";
import {openAlert} from "../../components/CommonUtilities/UtilityFunctions";
import GroupTableMetadata from "../../components/Details/Group/GroupTableMetadata";
import CustomizedBackdrop from "../../components/CommonUtilities/CustomizedBackdrop";
import CustomizedSnackbars from "../../components/CommonUtilities/CustomizedSnackbars";
import GroupDetailsTabsContainer from "../../components/Details/Group/GroupDetailsTabsContainer";


export default function GroupDetails(props) {
    const [group, setGroup] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const [alertStatus, setAlertStatus] = useState({open: false, message: "", severity:""});

    const handleChangeOnAlert = (message, severity) => {
        openAlert(setAlertStatus, message, severity);
    };

    const loadGroupApi = () => {
        return API.get("misServiciosEscolares", `/groups/${props.match.params.id}`);
    };

    const loadStudentsApi = () => {
        return API.get('misServiciosEscolares', "/students?details=basic");
    }

    const loadGroup = async () => {
        try {
            const responseGroupInfo = await loadGroupApi();
            setGroup(responseGroupInfo);
            setIsLoading(false);
            const responseStudents = await loadStudentsApi();
            setStudents(responseStudents);
        } catch (e) {
            alert('No se pudo conseguir la información. Por favor contacte al administrador');
        } 
    }

    useEffect(() => {
        if (!props.isAuthenticated) {
            return;
        }

        loadGroup();
    }, [props.match.params.id, props.isAuthenticated]);

    const presentBackdrop = (shouldItBePresented) => {
        setIsLoading(shouldItBePresented);
    }

    function renderGroup() {
        return (
            <div className="GroupDetails">
                <div className="GroupDetailsSection">
                    <GroupTableMetadata group={group} setIsLoading={setIsLoading}/>

                </div>
                <div>
                    <GroupDetailsTabsContainer
                        group={group}
                        students={students}
                        backdropCallback={presentBackdrop}
                        alertCallback={handleChangeOnAlert}
                        history={props.history}
                        groupPaymentsSummary={group.groupPaymentsSummary}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="GroupDetails">
            <CustomizedSnackbars status={alertStatus} alertCallback={handleChangeOnAlert}/>
            <CustomizedBackdrop open={isLoading}/>
            {props.isAuthenticated ? renderGroup() : renderLander()}
        </div>
    );
};