import React from 'react';

export default function StatisticDetails(props) {
    return (
        <div>
            {props.statistics ?
                <div>
                    <div>
                        <div>AMBIENTAL HOMBRES: {props.statistics.AMBIENTAL?.HOMBRES?.length}</div>
                        <div>
                            AMBIENTAL MUJERES: {props.statistics.AMBIENTAL?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            INDUSTRIAL HOMBRES: {props.statistics.INDUSTRIAL?.HOMBRES?.length}
                        </div>
                        <div>
                            INDUSTRIAL MUJERES: {props.statistics.INDUSTRIAL?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            PETROLERA HOMBRES: {props.statistics.PETROLERA?.HOMBRES?.length}
                        </div>
                        <div>
                            PETROLERA MUJERES: {props.statistics.PETROLERA?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            PSICOPEDAGOGIA HOMBRES: {props.statistics.PSICOPEDAGOGIA?.HOMBRES?.length}
                        </div>
                        <div>
                            PSICOPEDAGOGIA MUJERES: {props.statistics.PSICOPEDAGOGIA?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            ADMINISTRACION HOMBRES: {props.statistics.ADMINISTRACION?.HOMBRES?.length}
                        </div>
                        <div>
                            ADMINISTRACION MUJERES: {props.statistics.ADMINISTRACION?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            DERECHO HOMBRES: {props.statistics.DERECHO?.HOMBRES?.length}
                        </div>
                        <div>
                            DERECHO MUJERES: {props.statistics.DERECHO?.MUJERES?.length}
                        </div>
                    </div>
                    <div>
                        <div>
                            ENFERMERIA HOMBRES: {props.statistics.ENFERMERIA?.HOMBRES?.length}
                        </div>
                        <div>
                            ENFERMERIA MUJERES: {props.statistics.ENFERMERIA?.MUJERES?.length}
                        </div>
                    </div>
                </div> :
                <div>
                    No Data
                </div>
            }
        </div>
    );
};