import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import ButtonMaterialUIProgress from "../../CommonUtilities/ButtonMaterialUIProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

export default function StudentDetailsNotes({studentUuid}) {
    const [studentNotes, setStudentNotes] = useState("");
    const [newNote, setNewNote] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    function loadStudentNotesApi() {
        return API.get("misServiciosEscolares", `/notes/${studentUuid}`);
    }

    async function getNotes() {
        try {
            const response = await loadStudentNotesApi();
            setStudentNotes(response);
        } catch (e) {
            console.log("Error loading student notes", e);
        }
    }

    useEffect(() => {
        getNotes();
    }, [studentUuid]);

    function handleTextFieldChange(e) {
        setNewNote(e.target.value);
    }

    function setNewNotesApi(currentUser) {
        return API.put("misServiciosEscolares", "/notes", {
            body: {
                notes: newNote,
                studentUuid,
                author: {
                    email: currentUser?.attributes?.email,
                    name: currentUser?.attributes?.name,
                    sub: currentUser?.attributes?.sub,
                    username: currentUser?.username
                }
            }
        });
    }

    async function saveNewNote() {
        try {
            setIsLoading(true);
            const currentUser = await Auth.currentUserInfo();
            await setNewNotesApi(currentUser);
        } catch (e) {
            console.log(e);
            alert("Hubo un error al cargar las notas, por favor intente de nuevo");
        } finally {
            setNewNote("");
            setIsLoading(false);
            await getNotes();
        }
    }

    return (
        <>

            <div style={{marginBottom: '15px'}}>
                <Typography variant="h2" component="h2">
                    Notas
                </Typography>
                <div style={{marginBottom: '15px'}}>
                    <ButtonMaterialUIProgress
                        text="Guardar Información"
                        color='secondary'
                        variant='contained'
                        onClick={saveNewNote}
                        loading={isLoading}
                    />
                </div>

                <TextField
                    id="StudentDetailsNotesInput"
                    label="Notas"
                    multiline
                    variant="outlined"
                    rows={4}
                    fullWidth={true}
                    onChange={e => handleTextFieldChange(e)}
                    placeholder="Notas"
                    value={newNote}
                />
            </div>
            {
                studentNotes && studentNotes.length > 0 && studentNotes.map((note, index) => {
                    return (
                        <div key={index} style={{borderBottom: 'solid pink', paddingTop: '10px'}}>
                            <span style={{fontWeight: '500'}}>{note?.author?.email} {note?.author?.name ? " - " + note?.author?.name : ""}</span>
                            <p style={{color: 'gray'}}>{note?.createdAt}</p>
                            <p>{note?.notes}</p>
                        </div>
                    );
                })
            }

        </>
    );
}
