import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function ButtonAttachmentMaterialUIProgress(
    {
        loading,
        onChange,
        variant = "contained",
        color = "secondary",
        text = "Subir archivo",
        ...props
    }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <Button
                    variant={variant}
                    color={color}
                    component="label"
                    disabled={loading}
                >
                    {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    {text}
                    <input
                        type="file"
                        hidden
                        onChange={onChange}
                    />
                </Button>
            </div>
        </div>
    );
};