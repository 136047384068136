import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import FilterListIcon from "@material-ui/icons/FilterList";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {Print, PrintRounded} from "@material-ui/icons";
import PrintIcon from "@material-ui/icons/Print";

export const ICONS_OVERRIDE_SECONDARY_COLOR = {
    Filter: props => (
        <FilterListIcon color="secondary" fontSize="small"/>
    ),
    Search: props => (
        <SearchIcon color="secondary" fontSize="small"/>
    ),
    Clear: props => (
        <CloseIcon color="secondary" fontSize="small"/>
    ),
    SortArrow: props => (
        <ArrowDropDownIcon color="secondary" fontSize="small"/>
    ),
    ResetSearch: props => (
        <ClearIcon color="secondary" fontSize="small"/>
    ),
    FirstPage: props => (
        <SkipPreviousIcon color="secondary" fontSize="small"/>
    ),
    LastPage: props => (
        <SkipNextIcon color="secondary" fontSize="small"/>
    ),
    NextPage: props => (
        <NavigateNextIcon color="secondary" fontSize="small"/>
    ),
    PreviousPage: props => (
        <NavigateBeforeIcon color="secondary" fontSize="small"/>
    ),
    Delete: props => (
        <DeleteIcon color="secondary" fontSize="small"/>
    ),
    Edit: props => (
        <EditIcon color="secondary" fontSize="small"/>
    ),
    Save: props => (
        <EditIcon color="secondary" fontSize="small"/>
    ),
    Check: props => (
        <CheckIcon color="secondary" fontSize="small"/>
    ),
    Group: props => (
        <GroupIcon color="secondary" fontSize="small"/>
    ),
    Print: props => (
        <PrintRounded color="secondary" fontSize="small"/>
    ),
};