import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export default function ModalChooseSchedule({topicId, datelimit}) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);

    const updateTopicApi = date => {
        console.log(date)
        return API.put("misServiciosEscolares", `/topics/`, {
            body: {
                topicId: topicId,
                dateLimit: Date.parse(date)
            }
        });
    };

    const handleDateChange = async date => {
        setIsLoading(true);
        try {
            await updateTopicApi(date);
            setSelectedDate(date);
        } catch (e) {
            alert('No se puedo guardar la Hora satisfactoriamente, por favor intente de nuevo');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSelectedDate(datelimit ? datelimit : new Date());
        console.log(datelimit);
    }, [datelimit]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="normal"
                disabled={isLoading}
                id="modal-choose-limit-date-picker"
                label="Fecha Límite de Captura"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

