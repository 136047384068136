import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import BlockRounded from '@material-ui/icons/BlockRounded';
import SchoolRounded from '@material-ui/icons/SchoolRounded';
import SlideshowRounded from '@material-ui/icons/SlideshowRounded';
import People from '@material-ui/icons/People';
import PersonRounded from '@material-ui/icons/PersonRounded';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';import FolderRounded from '@material-ui/icons/FolderRounded';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {Divider, List, ListItemIcon, ListItemText, Tooltip} from "@material-ui/core";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';
import HowToRegRoundedIcon from '@material-ui/icons/HowToRegRounded';

function NavItems({onClick, userAttachmentsAccess, currentUserInfo}) {

    const primaryItems = [
        {id: "100", text: "Home", icon: <HomeIcon/>, navigation: "/", accessibleTo: ["ALL"]},
        {id: "200", text: "Estudiantes", icon: <PersonRounded/>, navigation: "/students-light", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "300", text: "Grupos", icon: <People/>, navigation: "/groups", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "400", text: "Profesores", icon: <SchoolRounded/>, navigation: "/professors", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "500", text: "Privilegios", icon: <BlockRounded/>, navigation: "/privileges", accessibleTo: ["MASTER"]},
        {id: "600", text: "Materias", icon: <SlideshowRounded/>, navigation: "/majors", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "700", text: "Usuarios", icon: <GroupAddIcon/>, navigation: "/cognito", accessibleTo: ["MASTER"]},
        {id: "800", text: "Registros", icon: <AddIcon/>, navigation: "/registrations", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "900", text: "Documentos", icon: <FileCopyIcon/>, navigation: "/students-documents", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "1000", text: "Formatería", icon: <LayersIcon/>, navigation: "/students-prints", accessibleTo: ["ADMIN", "MASTER"]},
        {id: "1100", text: "Acceso Archivos", icon: <FolderRounded/>, navigation: "/attachments-access", accessibleTo: ["MASTER"]},
    ];

    const secondaryItems = [
        {
            text: "Coord. Académica",
            icon: <AssignmentIcon/>,
            navigation: "/attachments/",
            id: "70e888dd-b201-43e9-bc3b-8fa42a4c0c50"
        },
        {
            text: "Enfermería",
            icon: <LocalHospitalIcon/>,
            navigation: "/attachments/",
            id: "161e57e3-b84a-48ae-8ab2-bcc82b5f8ad2"
        },
        {
            text: "Servicios Escolares",
            icon: <ContactSupportIcon/>,
            navigation: "/attachments/",
            id: "fd44aa9f-61f0-4de2-8f87-bb74cf586398"
        },
        {
            text: "Control Escolar",
            icon: <HowToRegRoundedIcon/>,
            navigation: "/attachments/",
            id: "5d6d4dc0-b21a-4c9c-95e5-f2e7bbc5ee6f"
        },
        {
            text: "Fernando",
            icon: <PersonRounded/>,
            navigation: "/attachments/",
            id: "259cee3b-e554-44dc-a801-f7df556c181c"
        },
        {
            text: "Liliana Álvarez",
            icon: <PersonRounded/>,
            navigation: "/attachments/",
            id: "e5b3a7a2-3e8e-4c4a-8c4b-7c8d9f3f0b6f"
        }
    ];

    return (
        <>
            <List>
                {
                    primaryItems
                        .filter(o => o?.accessibleTo?.includes("ALL") || o?.accessibleTo.includes(currentUserInfo?.attributes?.profile))
                        .map((item, index) => (
                            <>
                                <Tooltip title={item.text}>
                                    <ListItem
                                        button key={`primary-items-${index}`}
                                        onClick={e => onClick(e, item.navigation)}
                                    >
                                        <ListItemIcon>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={item.text}/>
                                    </ListItem>
                                </Tooltip>
                            </>
                        ))
                }
            </List>
            <Divider/>
            <ListSubheader inset>Archivos Compartidos</ListSubheader>
            {
                currentUserInfo &&
                <List>
                    {secondaryItems
                        .filter(item => userAttachmentsAccess?.includes(item.id))
                        .map((item, index) => (
                            <Tooltip title={item.text}>
                                <ListItem button key={`secondary-item-${index}`}
                                          onClick={e => onClick(e, `${item.navigation}${item.id}`)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.text}/>
                                </ListItem>
                            </Tooltip>
                        ))}
                </List>
            }

        </>
    );
}

export default NavItems;
