import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {PROFESSOR_TYPES, PROFESSORS_TITLES} from "../../../libs/Constants";

export function PROFESSORS_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Nombre',
            field: 'name',
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/professors/${rowData.professorId}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
            editComponent: props => (
                <TextField
                    value={props.value}
                    fullWidth
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Status',
            field: 'status',
            lookup:
                {
                    "ACTIVO": "Activo",
                    "INACTIVO": "Inactivo",
                    "PENDING": "Pendiente",
                    "CONFIRMED": "Verificado",
                }
        },
        {
            title: 'Título',
            field: 'title',
            lookup: PROFESSORS_TITLES
        },
        {
            title: 'Email',
            field: 'email'
        },
        {
            title: 'Tipo',
            field: 'type',
            lookup: PROFESSOR_TYPES
        },
        {
            title: 'Teléfono',
            field: 'phone'
        },

    ];
}

export const PROFESSORS_COLUMN_DEFINITION = [
    {
        title: 'Nombre',
        field: 'name',
        render: rowData => <a className="professorName" href={'/professors/'}>{rowData.name}</a>,
        // render: rowData => <a className="professorName" href={'/professors/' + rowData.teacherId}>{rowData.name}</a>,
        editComponent: props => (
            <TextField
                value={props.value}
                fullWidth
                onChange={e => props.onChange(e.target.value)}
            />
        )
    },
    {
        title: 'Status',
        field: 'status',
        lookup:
            {
                "ACTIVO": "Activo",
                "INACTIVO": "Inactivo",
                "PENDING": "Pendiente",
            }
    },
    {
        title: 'Título',
        field: 'title',
        lookup:
            {
                "LICENCIADO": "Licenciado",
                "LICENCIADA": "Licenciada",
                "INGENIERO": "Ingeniero",
                "INGENIERA": "Ingeniera",
                "MAESTRO": "Maestro",
                "MAESTRA": "Maestra",
                "DOCTOR": "Doctor",
                "DOCTORA": "Doctora",
            }
    },
    {
        title: 'Email',
        field: 'email'
    },
    {
        title: 'Teléfono',
        field: 'phone'
    },

];