import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    table: {
        minWidth: 650,
        fixedHeader: true
    },
    verticalHeader: {
        transform: "rotate(-90deg)"
    },
    tableHeaders: {
        height: "110px"
    },
    formGroup: {
        textAlign: 'center'
    },
    container: {
        boxShadow: 'none'
    }
}));
