import React, {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {Uploader} from "../../libs/UploadS3";
import {
    ATTACHMENTS_COLUMN_DEFINITION
} from "../../components/Material-TableCustomization/ColumnsDefinition/AttachmentsColumnDefinition";
import MaterialTable from "material-table";
import {REMOVE_DROP_SHADOW} from "../../components/Material-TableCustomization/CSSChanges/RemoveDropShadow";
import {
    ICONS_OVERRIDE_SECONDARY_COLOR
} from "../../components/Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../components/Material-TableCustomization/Localization/LocalizationES";
import ButtonAttachmentMaterialUIProgress from "../../components/CommonUtilities/ButtonAttachmentsMaterialUIProgress";

const K_FILES_NAMES = {
    "70e888dd-b201-43e9-bc3b-8fa42a4c0c50": "Coordinación Académica",
    "161e57e3-b84a-48ae-8ab2-bcc82b5f8ad2": "Enfermería",
    "fd44aa9f-61f0-4de2-8f87-bb74cf586398": "Servicios Escolares",
    "5d6d4dc0-b21a-4c9c-95e5-f2e7bbc5ee6f": "Control Escolar",
    "259cee3b-e554-44dc-a801-f7df556c181c": "Fernando",
    "e5b3a7a2-3e8e-4c4a-8c4b-7c8d9f3f0b6f": "Liliana Álvarez",
}

export default function AttachmentsContainer(props) {
    const {groupId} = props.match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [buttonText, setButtonText] = useState("Subir Archivo");

    const getListOfAttachmentsApi = async () => {
        const url = `/groups/${groupId}/attachments`;
        return await API.get("misServiciosEscolares", url);
    }

    const getListOfAttachments = async () => {
        setIsLoadingAttachments(true);
        try {
            const attachments = await getListOfAttachmentsApi();
            setAttachments(attachments);
        } catch (e) {
            alert("No se pudieron obtener los archivos adjuntos. Por favor intente de nuevo.");
        } finally {
            setIsLoadingAttachments(false);
        }
    }

    const uploadFile = async (file) => {
        setIsLoading(true);
        let percentage = undefined;
        const uploaderOptions = {
            fileName: file?.name,
            file: file,
            groupId,
            email: props?.currentUserInfo?.attributes?.email,
        };
        const uploader = new Uploader(uploaderOptions);

        await uploader
            .onStart(async ({fileName, fileSize, hasUploadStarted}) => {
                console.log("onStart");
                setIsLoading(true);
                setButtonText("Subiendo Archivo");
            })
            .onProgress(({percentage: newPercentage}) => {
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                    console.log(`${percentage}%`);
                    setButtonText(`Subiendo Archivo: ${percentage}%`);
                }
            })
            .onError((error) => {
                console.error(error);
                setIsLoading(false);
                setButtonText("Subir Archivo");
            })
            .onFinalizing((fileId, fileKey, filename) => {
                console.log("onFinalizing");
                setButtonText("Finalizando");
            })
            .onCompleted(async ({fileId, fileKey, filename}) => {
                console.log("onCompleted");
                setButtonText("Actualizando Archivos");
                await getListOfAttachments();
                setIsLoading(false);
                setButtonText("Subir Archivo");
            });

        await uploader
            .start()
    }

    const getPresignedUrl = async (fileId, fileKey, filename) => {
        const url = `/groups/${groupId}/attachments/${fileId}`;
        return await API.get("misServiciosEscolares", url);
    }
    const onClick = async (event, rowData) => {
        const {rowData: {fileId} = {}} = {rowData} || {};
        if (!fileId) {
            return;
        }
        try {
            const url = await getPresignedUrl(fileId);
            window.open(url, '_blank', 'noreferrer');
        } catch (e) {
            console.log(e.message);
        }
    }

    const onFileSubmission = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        await uploadFile(file);
    };

    useEffect(() => {
        getListOfAttachments();
    }, [props.match.params.id]);

    function renderAttachments() {
        return (
            <div>
                {isLoading}
                <div style={{marginBottom: '15px'}}>
                    <ButtonAttachmentMaterialUIProgress
                        loading={isLoading}
                        onChange={onFileSubmission}
                        text={buttonText}
                    />
                </div>

                <MaterialTable
                    columns={ATTACHMENTS_COLUMN_DEFINITION(onClick)}
                    data={attachments}
                    title={`Archivos ${K_FILES_NAMES[groupId] || ""}`}
                    isLoading={isLoadingAttachments}
                    options={{
                        search: true,
                        pageSize: 10,
                    }}
                    components={{
                        ...REMOVE_DROP_SHADOW
                    }}
                    icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                    localization={LOCALIZATION('archivo', 'archivos')}
                />
            </div>
        )
    }

    return (
        <>
            {props.isAuthenticated ? renderAttachments() : props.history.push("/login")}
        </>
    );
};
