export let LOCALIZATION_GLOBAL_ES = {
    toolbar: {
        nRowsSelected: '{0} grupos(s) seleccionadas',
        searchPlaceholder: "Filtrar",
        searchTooltip: "Buscar",
    },
    header: {
        actions: 'Acciones'
    },
    body: {
        emptyDataSourceMessage: 'No hay grupos que mostrar',
        filterRow: {
            filterTooltip: 'Filtrar'
        },
        editRow: {
            deleteText: 'Seguro que desea eliminar este grupo?'
        }
    },
};

export function LOCALIZATION(singular='grupo', plural='grupos') {
    return {
        toolbar: {
            nRowsSelected: `{0} elemento(s) seleccionado(s)`,
            searchPlaceholder: `Filtrar ${plural}`,
            searchTooltip: `Buscar ${singular}`,
        },
        header: {
            actions: `Acciones`
        },
        body: {
            emptyDataSourceMessage: `No hay ${plural} que mostrar`,
            filterRow: {
                filterTooltip: `Filtrar ${singular}`
            },
            editRow: {
                deleteText: `Seguro que desea eliminar este ${singular}?`
            }
        },
    };
}