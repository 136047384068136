import React, { useState } from "react";
import MaterialTable from "material-table";
import { LOCALIZATION } from "../../Material-TableCustomization/Localization/LocalizationES";
import { ICONS_OVERRIDE_SECONDARY_COLOR } from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import { REMOVE_DROP_SHADOW } from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import GroupMultipleSelect from "./GroupMultiSelect";

export default function GroupTableTesoreria({ groupPaymentsSummary }) {
    const [selectedKeys, setSelectedKeys] = useState(["name", "studentId"]);

    const handleChange = (selectedKeysOnModal) => {
        setSelectedKeys(selectedKeysOnModal);
    };

    return (
        <MaterialTable
            title=""
            data={groupPaymentsSummary.data}
            columns={groupPaymentsSummary.keys.filter(
                (o) => selectedKeys.indexOf(o.field) !== -1
            )}
            options={{
                search: false,
                paging: false,
                // toolbar: false,
                showTitle: false,
                headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                    position: "sticky",
                    top: 0,
                },
            }}
            components={{
                ...REMOVE_DROP_SHADOW,
                Toolbar: (props) => (
                    <div>
                        <GroupMultipleSelect
                            selectedKeys={selectedKeys}
                            setSelectedKeys={handleChange}
                            availableKeys={groupPaymentsSummary.keys.map((o) => o.field)}
                        />
                    </div>
                ),
            }}
            localization={LOCALIZATION("pago", "pagos")}
            icons={ICONS_OVERRIDE_SECONDARY_COLOR}
        />
    );
}
