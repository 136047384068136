import React from "react";
import TextField from "@material-ui/core/TextField";
import {majors, statuses} from "../../CommonUtilities/UtilityFunctions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export function STUDENTS_LIGHT_COLUMN_DEFINITION_PROPS(props) {
    return [
        {
            title: 'Nombre',
            field: 'name',
            render: rowData =>
                <Button color='secondary' onClick={() => props.history.push(`/students/${rowData.studentUuid}`)}
                        component={Link}>
                    {rowData.name}
                </Button>,
            editComponent: props => (
                <TextField
                    value={props.value}
                    fullWidth
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Matrícula',
            field: 'studentId',
        },
        {
            title: 'Carrera',
            field: 'major',
            lookup: majors()
        },
        {
            title: 'Cuatrimestre',
            field: 'cuatrimestre',
            lookup:
                {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10+": "10+",
                }
        },
        {
            title: 'Grupo',
            field: 'groupDisplay',
            editable: 'never',
            render: rowData =>
                <Button
                    color='secondary'
                    onClick={() => props.history.push(`/groups/${rowData?.groupId}`)}
                    component={Link}
                >
                    {rowData?.groupDisplay}
                </Button>,
        },
        {
            title: 'Status',
            field: 'status',
            lookup: statuses(),
        }
    ];
}
