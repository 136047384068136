import React from 'react';
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function CustomizedBackdrop(props) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Backdrop className={classes.backdrop} open={props.open}>
                <CircularProgress color="inherit"/>
            </Backdrop>
      </div>
  );
}
