import React, {useEffect, useState} from "react";
import ButtonAttachmentMaterialUIProgress from "../../CommonUtilities/ButtonAttachmentsMaterialUIProgress";
import {API} from "aws-amplify";
import {REMOVE_DROP_SHADOW} from "../../Material-TableCustomization/CSSChanges/RemoveDropShadow";
import MaterialTable from "material-table";
import {
    ICONS_OVERRIDE_SECONDARY_COLOR
} from "../../Material-TableCustomization/IconsOverride/IconsOverrideSecondaryColor";
import {LOCALIZATION} from "../../Material-TableCustomization/Localization/LocalizationES";
import {
    ATTACHMENTS_COLUMN_DEFINITION
} from "../../Material-TableCustomization/ColumnsDefinition/AttachmentsColumnDefinition";
import {Uploader} from "../../../libs/UploadS3";

export default function GroupTableAttachments({group: {groupId}}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const getListOfAttachmentsApi = async () => {
        const url = `/groups/${groupId}/attachments`;
        return await API.get("misServiciosEscolares", url);
    }

    const getListOfAttachments = async () => {
        setIsLoadingAttachments(true);
        try {
            const attachments = await getListOfAttachmentsApi();
            console.log(attachments);
            setAttachments(attachments);
        } catch (e) {
            console.log(e);
            alert("No se pudieron obtener los archivos adjuntos. Por favor intente de nuevo.");
        } finally {
            setIsLoadingAttachments(false);
        }
    }

    const uploadFile = async (file) => {
        let percentage = undefined;
        const uploaderOptions = {
            fileName: file?.name,
            file: file,
            groupId
        };
        const uploader = new Uploader(uploaderOptions);

        await uploader
            .onProgress(({percentage: newPercentage}) => {
                // to avoid the same percentage to be logged twice
                if (newPercentage !== percentage) {
                    percentage = newPercentage;
                }
            })
            .onError((error) => {
                console.error(error);
            })

        await uploader.start();
    }

    const getPresignedUrl = async (fileId, fileKey, filename) => {
        const url = `/groups/${groupId}/attachments/${fileId}`;
        return await API.get("misServiciosEscolares", url);
    }
    const onClick = async (event, rowData) => {
        const {rowData: {fileId} = {}} = {rowData} || {};
        if (!fileId) {
            return;
        }
        try {
            const url = await getPresignedUrl(fileId);
            window.open(url, '_blank', 'noreferrer');
        } catch (e) {
            console.log(e.message);
        }
    }

    const onFileSubmission = async (e) => {
        const file = e.target.files[0];
        console.log(file);
        e.preventDefault();
        setIsLoading(true);

        try {
            await uploadFile(file);
        } catch (e) {
            alert(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getListOfAttachments();
    }, [groupId]);

    return (
        <div>
            <div style={{marginBottom: '15px'}}>
                <ButtonAttachmentMaterialUIProgress
                    loading={isLoading} onChange={onFileSubmission}
                />
            </div>

            <MaterialTable
                columns={ATTACHMENTS_COLUMN_DEFINITION(onClick)}
                data={attachments}
                title="Archivos"
                isLoading={isLoadingAttachments}
                options={{
                    search: true,
                    pageSize: 20,
                }}
                components={{
                    ...REMOVE_DROP_SHADOW
                }}
                icons={ICONS_OVERRIDE_SECONDARY_COLOR}
                localization={LOCALIZATION('archivo', 'archivos')}
            />

        </div>
    );
};
