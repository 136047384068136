import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import {UVT_STARTING_CYCLES} from "../../../libs/Constants";
import {API} from "aws-amplify";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
export default function GroupTableMetadata({group, setIsLoading}) {
    const classes = useStyles();
    const [startingCycle, setStartingCycle] = useState(group.startingCycle ?? "");

    const updateGroupApi = startingCycle => {
        return API.put("misServiciosEscolares", `/groups/${group.groupId}`, {
            body: {startingCycle: startingCycle}
        });
    }

    const handleChange = async (event) => {
        setIsLoading(true);
        const groupId = event.target.value;
        try {
            await updateGroupApi(groupId);
            setStartingCycle(groupId);
        } catch (e) {
            alert("No se pudo actualizar el ciclo de inicio del grupo");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setStartingCycle(group.startingCycle);
    }, [group.startingCycle]);

    return (
        <>
            {group ?
                <>
                    <Typography variant="h4" component="h2">
                        GRUPO: {group.major}-{group.cuatrimestre}{group.group} ({group.plan})
                    </Typography>
                    <Typography variant="h5" component="h2">
                        RVOE: {group.rvoe}
                    </Typography>
                    <Typography variant="h5" component="h2">
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Ciclo de Inicio</InputLabel>
                            <Select
                                labelId="starting-cycle-label"
                                id="starting-cycle-id"
                                value={startingCycle}
                                onChange={handleChange}
                            >
                                {UVT_STARTING_CYCLES.map(o => (
                                    <MenuItem value={o} key={o}>{o}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Typography>
                </>
                : <></>}
        </>);
}