import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContentText from "@material-ui/core/DialogContentText";
import {API} from "aws-amplify";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: '',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    }
}));

export default function DialogProfileSelection({openProfileDialog, setOpenProfileDialog, selectedUserAttributes, setIsLoading, loadUsers}) {
    const classes = useStyles();
    const [profile, setProfile] = useState("");

    const updateUserProfile = async () => {
        console.log("updateProfile");
        setIsLoading(true);
        setOpenProfileDialog(false);
        const url = `/cognito/users/${selectedUserAttributes.Username}/attributes`;
        try {
            await API.post("misServiciosEscolares", url, {
                body: {
                    Name: "profile",
                    Value: profile
                }
            });
        } catch (e) {
            alert("No se pudo cambiar el perfil del usuario, por favor contacte al administrador: Ing. Carlos Ochoa 993 370 6587");
        } finally {
            loadUsers();
        }
    }

    const handleChange = (event) => {
        setProfile(event.target.value);
    };

    const handleClose = () => {
        setOpenProfileDialog(false);
    };

    const K_PROFILE_OPTIONS = [
        {value: 'MASTER', label: 'Master'},
        {value: 'ADMIN', label: 'Administrador'},
        {value: 'TESORERIA', label: 'Tesorería'},
        {value: 'PROFESSOR', label: 'Profesor'},
    ];

    useEffect(() => {
        setProfile(selectedUserAttributes.profile);
    }, [selectedUserAttributes]);

    return (
        <div>
            <div>
                <Dialog open={openProfileDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Cambiar Perfil</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Cambiar Perfil del usuario {selectedUserAttributes.email}:
                        </DialogContentText>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="demo-dialog-native">Perfil</InputLabel>
                                <Select
                                    native
                                    value={profile}
                                    onChange={handleChange}
                                    fullWidth
                                    input={<Input id="demo-dialog-native"/>}
                                >
                                    {K_PROFILE_OPTIONS.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={updateUserProfile} color="primary">
                            Cambiar Perfil
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};
