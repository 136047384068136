import React from "react";
import TextField from "@material-ui/core/TextField";
import {formatPhoneNumber, majors, statuses} from "../../CommonUtilities/UtilityFunctions";

export function STUDENTS_COLUMN_DEFINITION(enabledColumns) {
    let columns = [
        {
            title: 'Nombre',
            field: 'name',
            render: rowData => <a className="studentsName" href={'/students/' + rowData.studentUuid}>{rowData.name}</a>,
            editComponent: props => (
                <TextField
                    value={props.value}
                    fullWidth
                    onChange={e => props.onChange(e.target.value)}
                />
            )
        },
        {
            title: 'Matrícula',
            field: 'studentId',
        },
        {
            title: 'Carrera',
            field: 'major',
            lookup: majors()
        },
        {
            title: 'Cuatrimestre',
            field: 'cuatrimestre',
            lookup:
                {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "10+": "10+",
                }
        },
        {
            title: 'Email',
            field: 'email',
        },
        {
            title: 'Teléfono',
            field: 'phone',
            render: rowData => <>{formatPhoneNumber(rowData.phone)}</>
        },
        {
            title: 'Notas',
            field: 'notes',
        },
        {
            title: 'Modalidad',
            field: 'mode',
            lookup:
                {
                    "ESCOLARIZADO": "Escolarizado",
                    "SABADOS": "Sábados",
                    "DOMINGOS": "Domingos",
                }
        },
        {
            title: 'CURP',
            field: 'curp',
        },
        {
            title: 'Activo',
            field: 'status',
            lookup: statuses(),
        },
    ];
    let enabledColumnsKeys = Object.keys(enabledColumns);
    for (let i = 0; i < enabledColumnsKeys.length; i++) {
        if (!enabledColumns[enabledColumnsKeys[i]]['enabled']) {
            columns = columns.filter(column => column['field'] !== enabledColumnsKeys[i]);
        }
    }
    return columns;
};
