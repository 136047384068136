import React, {useEffect, useState} from 'react';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useStyles} from "./TopicTableKardexStyles";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {verifyPayments} from "../../CommonUtilities/UtilityFunctions";
import TextField from "@material-ui/core/TextField";
import {API} from "aws-amplify";

function TopicTableKardexDetailed({grades, topicId, groupId}) {
    const classes = useStyles();
    const [gradesNurse, setGradesNurse] = useState([]);
    const handleChange = (nameValue, index) => ({target: {value}}) => {
        if (value < 0 || value > 10) {
            alert('La calificación debe estar entre 0 y 10');
            return;
        }
        // Splitting gradesNurse into two arrays based on the index value
        // and substituting the grade value for the new one
        const tmpGrades = gradesNurse[index];
        tmpGrades.grades[nameValue].grade = value;
        tmpGrades.gradesAverage = parseFloat(Object.values(tmpGrades.grades)
            .reduce((acc, grade) => acc + parseFloat(grade.grade || 0), 0) / getDenominator(tmpGrades.grades))
            .toFixed(2);
        console.log(tmpGrades);

        setGradesNurse([
            ...gradesNurse.slice(0, index),
            tmpGrades,
            ...gradesNurse.slice(index + 1)
        ]);
    };
    const getDenominator = (grades) => {
        let denominator = 0;
        for (const grade of Object.values(grades)) {
            if (+grade?.grade) {
                denominator += 1;
            }
        }
        return denominator;
    };

    const updateGradesApi = () => {
        return API.put("misServiciosEscolares", `/enfermeria/${topicId}/grades`, {
            body: {
                grades: gradesNurse,
                groupId
            }
        });
    };
    const updateGrades = async () => {
        try {
            await updateGradesApi();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        setGradesNurse(grades);
    }, [grades]);

    return (
        <div>
            <Button
                color='secondary'
                variant='contained'
                onClick={updateGrades}
                // disabled={topicHasBeenGraded}
            >
                Guardar cambios
            </Button>
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="simple dense table">
                    <TableHeader/>
                    <TableBody>
                        {gradesNurse
                            ? gradesNurse.map((row, index) => (
                                <TableRow
                                    key={index}
                                    style={{backgroundColor: verifyPayments(row.status) ? '#F08080' : 'clear'}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.studentId ||"TMP"}
                                    </TableCell>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.grades?.FIRST?.grade}
                                            type='number'
                                            step={0.1}
                                            onChange={handleChange("FIRST", index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.grades?.SECOND?.grade}
                                            type='number'
                                            step={0.1}
                                            onChange={handleChange("SECOND", index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={row.grades?.THIRD?.grade}
                                            type='number'
                                            step={0.1}
                                            onChange={handleChange("THIRD", index)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            disabled
                                            value={`${parseFloat(row?.gradesAverage).toFixed(2) || ""}`}
                                        />
                                    </TableCell>
                                </TableRow>
                            )) : <>
                            </>}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}

const TableHeader = () => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow className={classes.tableHeaders}>
                <TableCell>Matrícula</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Parcial 1</TableCell>
                <TableCell>Parcial 2</TableCell>
                <TableCell>Parcial 3</TableCell>
                <TableCell>Final</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TopicTableKardexDetailed;